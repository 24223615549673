<template>
	<div>
		<label for="paperUpload" class="dropzone" 
			:class="{hightlight: hightlight, disabled: !enabled}">
			<!-- <div class="fileUploaderWaitingStatus">Add files</div> -->
			<div v-if="this.state=='uploading'" class="addFileIconWrapper">
				<div class="loaderStatusIcon">
					<div class="loader">
							<svg class="circular-loader" viewBox="25 25 50 50" >
							<circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#70c542" stroke-width="2" />
							</svg>
					</div>
				</div>
			<p class="loadingFileIconTitle">Loading file</p>
			</div>
			<div v-if="this.state=='error'" class="loaderStatusIcon">
				<svg width="80" height="80" viewBox="0 0 24 24">
					<path d="M0 0h24v24H0z" fill="none"/>
					<path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" fill="#f66"/>
				</svg>
				<p class="addFileIconTitle">{{this.errorMessage}}</p>
			</div>
<!-- 			<div v-if="this.state=='success'" class="loaderStatusIcon">
				<svg width="80" height="80" viewBox="0 0 50 50" xml:space="preserve" fill="#000000">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier"> 
					<circle style="fill:#25AE88;" cx="25" cy="25" r="25"></circle> 
					<polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points=" 38,15 22,33 12,25 "></polyline> </g>
				</svg>

				<p class="addFileIconTitle">Failed to upload file. Please try again later.</p>
			</div> -->
			<div v-if="this.state=='ready'" class="loaderStatusIcon addFileIconWrapper" title="Add file">
				<div class="addFileIcon">
					<div class="addFileIconImage">
						<span>+</span>
					</div>
				</div>
				<p class="addFileIconTitle">Add file</p>
			</div>

			<div
			@dragover="onDragOver"
			@dragleave="onDragLeave"
			@drop="onDrop"
			>

				<input
					name="file"
					type="file"
					@change="onFileSelected"
					class="paperUpload"
				/>

			</div>
		</label>


    </div>
</template>

<script>
const findLastAlphaNumericIndex = (doiString)=> {
	let lastLAN = doiString.replaceAll("[^a-zA-Z0-9]*$", "").length;
	return lastLAN;
	};
// const maxFileSize = fileSizeMb * 1024 * 1024;
import authHeader from '../services/auth-header';
export default {
	props:["apiUrl"],
	components:{
	},
	data() {
		return {
			state:'ready',
			hightlight:false,
			enabled:true,
			filename:"",
			errorMessage:"",
			currentPaper:{'attachedFiles':[]}
			}
	},
	computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    maxFileSize() {
			let fileSizeMb = this.$store.state.globalSettings.maxFreeFileSize;
			if(this.currentUser && this.$store.state.globalSettings.proFuncStatus.includes(this.currentUser.status))
				fileSizeMb = this.$store.state.globalSettings.maxProFileSize;
			return fileSizeMb * 1024 * 1024;
    }
	},
	methods: {
		onDragOver(event) {
			if(this.enabled) {
				event.preventDefault();
				this.hightlight = true;
			}
		},

		onDragLeave(event) {
			this.hightlight = false;
		},
		onDrop() {
			this.hightlight = false;
			this.enabled = true;
			this.state='uploading';
		},
		uploadFile(file) {
			this.$store.state.globalSettings.iconState="SUBMITTING";
			const formData = new FormData();
			let urlExt = '';
			if(this.$route.query.accessKey) {
				urlExt = `&accessKey=${this.$route.query.accessKey}`;
			}
			formData.append("file", file);
			fetch(this.apiUrl+urlExt, {
			method: 'POST',
			headers: authHeader(),
			body: formData
			}).then(r => {
        if(r.status==200) {
				r.json().then(json=>{
				this.$store.state.globalSettings.iconState="DEFAULT";
				this.state="success";
				let fileMetaData = {'filename':json.filename,'originalFilename':json.origFilename}

        if(json.metaDataExtracted) {
					let extractedDoi = '';
					if(json.metaDataDoi) {
						extractedDoi = json.metaDataDoi;
						let lastANSI = findLastAlphaNumericIndex(extractedDoi);
						extractedDoi = extractedDoi.substr(0, lastANSI);
					}
					fileMetaData = {...fileMetaData, metaDataExtracted:true, metaDataTitle:json.metaDataTitle, metaDataDoi:extractedDoi}
        }
				this.$emit("fileUploaded",fileMetaData);
				});
			}
			else {
				this.state="error";

				this.$store.state.globalSettings.iconState="ERROR";
				r.json().then(json=>{
					this.errorMessage=json.message;
				});
			} }
			).catch( error=>{
				this.$store.state.globalSettings.iconState="ERROR";
				this.state="error";
				this.errorMessage=error;
			}
			);
		},
		onFileSelected(event) {
			this.state='uploading';
			let currentFile = event.target.files[0];
			this.filename=currentFile.name;
			if(currentFile.size > this.maxFileSize) {
				this.errorMessage = "File is too large";
				this.state='error';
				return;
			}
			if(currentFile.type!="application/pdf") {
				this.errorMessage = "Incorrect file format. Only PDF files are acceptable";
				this.state='error';
				return;
			}
			this.uploadFile(currentFile);
		}
	}
 };

</script>
<style scoped>
.dropzone:hover .addFileIconImage, .dropzone:hover .addFileIconTitle {
	color:#555;
	border-color: #555555;
}
.addFileIconTitle {
	color: #888888;
}
.loadingFileIconTitle {
	position: relative;
	top:5em;
	left:0;
	right: 0;
	width: 100%;
}
.addFileIconImage {
	width:1em;
	height: 1em;
	border: 4px dashed #888888;
	border-radius: 0.2em;
}
.addFileIcon {
	font-size: 4em;
	color:#888888;
	display: flex;
	justify-content: center; align-items: center;
	line-height: 0.75em;
}
.addFileIconTitle {
	text-align: center;
}
.paperUpload {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	left:0;
	top:0;
  z-index:9;
}
.dropzone {
  position: relative;
  border: 3px dashed #888888;
  color: #333333;
  height: 100%;
  min-height: 8em;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  z-index:10;
}

.hightlight {
  background-color: #ebebeb;
}
.disabled {
  border: 3px dashed grey;
  color: grey;
}
.loaderStatusIcon .addFileIconTitle {
	font-size: 0.75em;
}
.loaderStatusIcon{
    top:15%;
    position: absolute;
    left:0;
    right:0;
    height: 4em;
    z-index: 7;
}

.loaderStatusIcon .loader {
  position: relative;
  margin: 0px auto;
  width: 4em;
  height: 4em;
}
.loaderStatusIcon .loader:before {
  content: '';
  display: block;
/*  padding-top: 100%;*/
}

.circular-loader {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
}

.loader-path {
  stroke-dasharray: 150,200;
  stroke-dashoffset: -10;
  -webkit-animation: dash 3.5s ease-in-out infinite, color 10s ease-in-out infinite;
          animation: dash 3.5s ease-in-out infinite, color 10s ease-in-out infinite;
  stroke-linecap: round;
  transition: stroke 2s;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}
@-webkit-keyframes color {
  0% {
    stroke: #B1DAE7;
  }
  40% {
    stroke: #B1DAE7;
  }
  66% {
    stroke: #70c542;
  }
  80%, 90% {
    stroke: #70c542;
  }
}
@keyframes color {
  0% {
    stroke: #B1DAE7;
  }
  40% {
    stroke: #B1DAE7;
  }
  66% {
    stroke: #70c542;
  }
  80%, 90% {
    stroke: #70c542;
  }
}

</style>