<template>
	<div class="searchForm">
		<div class="searchFormBox">
			<input type="text" class="searchFormControl" v-model="searchQuery" @change="updateSearchQuery" @keypress.enter="updateSearchQuery" :maxlength="this.$store.state.globalSettings.searchMaxLength">
			<!-- <span v-show="modelValue.searchActive" title="Clear Search" class="searchBoxIcon searchBoxClearIcon"><i class="fa fa-xmark"></i></span> -->
			<span class="searchBoxIcon searchBoxSearchIcon"><i class="fa fa-search"></i></span>
		</div>

		<div v-show="searchBoxOpen" class="searchSettings">
			Filter parameters
<div title="Case Sensitive">
  Aa <div class="form-check form-check-inline funkyradio-success" id="readCheckBoxBlock">
    <input class="form-check-input" type="checkbox" v-model="caseSensitive" @change="updateSearchQuery">
    <label class="form-check-label" for="readCheckBox"></label>
  </div>
</div>
		</div>
	</div>
</template>
<script>
	export default {
	components:{
		
	},
	computed: {
	},
	props:[
		"modelValue","searchBoxOpen"
	],
	emits: ['update:modelValue'],
	data() {
		return {
			searchQuery:"",
			caseSensitive:false,
			parametersOpen:false
		}
	},
	methods: {
		updateSearchQuery() {
			let searchActive = false
			if(this.searchQuery.length>0) {
				searchActive = true
			}
			this.$emit('update:modelValue', {...this.modelValue,'query':this.searchQuery, 'caseSensitive':this.caseSensitive, 'searchActive':searchActive});
		},
		openCloseParameters() {
			this.parametersOpen=!this.parametersOpen;
		},
		resetSearch() {
			this.searchQuery="";
			this.caseSensitive=false;
			this.updateSearchQuery();
		}
	}
}
</script>
<style>
.searchSettings {
	position: absolute;
	background-color: #ffffff;
	width:100%;
	height: auto;
	border:1px solid #ebebeb;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
	padding: 0.75em;
}
.searchFormBox {
	position: relative;
	border-radius: 0.5em;
	border:1px solid #C7CED5;
	background-color: #d8ecf3;
}
.searchBoxIcon {
/*  position: absolute;*/
  top:0.25em;
  color:#555555;
  overflow: hidden;
  transition: color 0.4s;
  text-indent: -999px;
  overflow: hidden;
/*  width: 40px;*/
	padding: 0;
	margin: 0;
	font-size:1em;
}
.searchBoxSearchIcon {
/*	right: 20px;*/
margin-right:10px;
/*	box-sizing: border-box;*/
}
.searchBoxClearIcon {
	right: 1.5em;
}
.searchBoxClearIcon:hover {
	color:#000000;
}
.searchFormControl {
	margin: 0;
/*	background-color: #d8ecf3;*/
	max-width: 20vw;
	border: 0px solid transparent;
	margin-right: 0.5em;
	border-radius: inherit;
	background-color: inherit;
	box-shadow: none;
	height: 2em;
	transition: border-color 0.4s;
}

.searchFormControl:focus{
  box-shadow: none;
/*  border-color: #ebebeb;*/
/*border:1px solid #ebebeb;*/
    outline:none;
    outline-width: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.searchFormControl:focus ~ .searchBoxIcon {
  color: #8a99a8;
}
.searchForm {
	z-index: 3;
  display: flex;
/*max-width: 20vw;*/
}
</style>