<template>
  <div class="errorAlert">
    <div class="alert" :class="errorMessageClass" role="alert">
      {{ alertMessage }}
      <div class="errorAlertButton">
      <PaperToolsButton
		buttonTitle="Yes"
		buttonIcon="fa-xmark"
		@click="alertAcceptMethod"></PaperToolsButton>
		<PaperToolsButton
      buttonTitle="No"
      buttonIcon="fa-xmark"
      @click="alertRejectMethod"
      buttonStyle="cancel"></PaperToolsButton>
    </div>
    </div>
  </div>
</template>
<script>
  
import PaperToolsButton from '@/components/mainView/PaperToolsButton';
export default {

  components: {
	PaperToolsButton
  },
  name: "MainAppBoard",
  props: ["alertMessage","errorMessageClass"],
  methods: {
        alertRejectMethod() {
          this.$emit("alertRejectMethod")
        },
        alertAcceptMethod() {
          this.$emit("alertAcceptMethod")
        },
  }
}

</script>
<style>
  .errorAlert {
/*border:1px solid #3ca2c3;*/
  box-shadow: 1px 1px 1px 0px rgba(158, 158, 158, 0.25);
  border: 1px solid #ebebeb;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
top:50%;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
position: absolute;
width:40%;
min-width:30vw;
min-height: 5vh;
background: #ffffff;
}
.errorAlertHeader {
  min-height: 2em;
  background-color: #B1DAE7;
  color:#ffffff;
}
.errorAlertContent {
  min-height: 4em;
}
.errorAlertButton {
  min-height: 2em;
  padding-bottom:0.5em;
  padding-top:1em;
  text-align: center;
}
.errorAlertButton button {
  margin-left:1em;
  margin-right:1em;
  border-radius:0.5em;
  padding:0.25em 1em 0.25em 1em;
}

</style>