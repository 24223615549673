export const globalSettings = {
// const serverUrl = "https://paperpusherappbackend.ey.r.appspot.com"
  namespaced: true,
  state: {
        // backendUrl:'http://localhost:8080',
        backendUrl:'https://papers.tools',
        maxFreeBoards:10,
        maxFreePapers:50,
        maxFreeFileSize:1,
        maxProFileSize:10,
        proFuncStatus:['pro','test','admin'],
        apis: {
          doiAPI:'/api/doi'

        },
        savingError:false,
        minStatusChangeTime:400,
        searchMaxLength:100,
        redirectTimeout:1000,
        maxBoardNameLength:50,
        maxBoardDescLength:80,
        maxCategoryNameLength:30,
        maxGroupNameLength:40,
        maxSearchLength:50,
        currentTheme:'light',

        themes: {

          light: {
            background:"#ffffff",
            mainColor:"#B1DAE7",
            iconColor:"#2196F3",
            descColor:"#777777",
            titleColor:"#333333",
            invertedTitleColor:"#555555",
            paperTitleColor:"#444444",
            listItemDelimeter:"#333333",
            listItemHover:"#e6e6e6",
            formBackground:"#ffffff",
            formBackgroundHover:"#ebebeb",
            ratingBgColorDefault:"#a7a8a8",
            tooltipBackgrond:"#555555",



          },

          dark: {
            background:"#222222",
            mainColor:"#2196F3",
            iconColor:"#ffffff",
            paperTitleColor:"#999999",
            titleColor:"#eeeeee",
            invertedTitleColor:"#333333",
            descColor:"#999999",
            listItemDelimeter:"#333333",
            listItemHover:"#e6e6e6",
            formBackground:"#333333",
            formBackgroundHover:"#555555",
            ratingBgColorDefault:"#DEDFDF",
            tooltipBackgrond:"#aaaaaa",
          }
        },
        iconState:'DEFAULT',
        showSettingCurtain:false,
        showSettingCurtainFunction:undefined,
        userMenuOpen:false,
        showAppCurtainFunction:false,
        showAppCurtain:false,

  },
  actions: {

  },
  mutations: {
    changeIconState (state, newState) {
      state.iconState = newState;
    }

  }
};
