<template>
	<div>
		<img src="@/assets/PDF_icon.svg" width="32" /> <a class="uploadedFileLink" title="Open file" target="_blank"  @click="requestFile">{{fileObject[0].originalFilename.substring(0, 32)}}</a> <span title="Delete file" class="uploadedFileDeleteIcon" @click="deleteFile"><i class="fa fa-xmark"></i></span>
	</div>
</template>
<script>
	// :href="fileDownloadLink"

import authHeader from '../services/auth-header';
export default {
	props:['fileObject','deleteApiUrl','downloadApiUrl'],
	components:{
	},
	computed: {
		accessKeyExt() {
			let urlExt = '';
			if(this.$route.query.accessKey) {
				urlExt = `&accessKey=${this.$route.query.accessKey}`;
			}
			return urlExt;
		},
		fileDownloadLink() {
			return `${this.downloadApiUrl}&fileName=${this.fileObject[0].filename}`+this.accessKeyExt;
		}
	},
	data() {
		return {
			}
	},
	methods: {
		requestFile() {
			fetch(this.fileDownloadLink,{
			method: 'GET',
			headers:authHeader()
			}).then(r => {
				if(r.status==200) {
				// console.log(r);
				r.blob()
				.then(data => window.open(URL.createObjectURL(data),"_blank",))
				// window.open("data:application/pdf," + encodeURI(r.arraybuffer())); 
// 
				}
				}
			).catch( error=>{
			}
			);
		},

		openFile() {

		},
		deleteFile() {
					// 	axios.get(this.fileDownloadLink, ,responseType: 'blob'})
		// 	.then(r => {
        //     if(r.status==200) {
				
        // var file = new Blob([data], {type: 'application/pdf'});
        // var fileURL = URL.createObjectURL(file);
        //     }
        // })
			let deleteUrl = `${this.deleteApiUrl}&fileName=${this.fileObject[0].filename}`+this.accessKeyExt;
			fetch(deleteUrl,{
			method: 'GET',
			headers:authHeader()
			}).then(r => {
            if(r.status==200 || r.status==500) {
				r.json().then(json=>{
					this.$emit("fileDeleted",{'file':this.fileObject[0].id});
				});
			}
			}
			).catch( error=>{
			}
			);
		}
	}
 };
</script>
<style>
	.uploadedFileLink {
		color:#888;
		text-decoration: none;
	}
	.uploadedFileLink:hover {
		color: #333;
		text-decoration: underline;
	}
	.uploadedFileDeleteIcon {
		color:#888;
	}
	.uploadedFileDeleteIcon:hover {
		color:#333;
	}
</style>