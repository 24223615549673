<template>
		<span :class="currentStatus">
		<i class="fas fa-exclamation" aria-hidden="true"></i>
		</span>
</template>

<script>
	
export default {
	props:{
    modelValue: Boolean,
    statusType: String
  },
  emits: ['update:modelValue'],
  computed: {
	currentStatus() {
		if(this.modelValue) {
			return "statusShown";
		}
		return "statusHidden";
		}
	}
 }

</script>
<style type="text/css">
.statusShown {
  display: inline !important;
}
.statusHidden {
  display: none !important;
}
</style>