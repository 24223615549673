<template>

	<div class="colorSelector">
		<ColorPickerItem
		v-show="openPickerState"
        v-for="(colorItem, index) in colors"
        :itemColor="colorItem"
        :key="index"
		:currentColor="this.modelValue"
		@color-selected="selectColor"
        >
		</ColorPickerItem>

		<div v-show="!openPickerState" class="colorSelectorCurrent" @click="openColorPicker" :title="this.colorTitle">
		</div>
	</div>
</template>

<script type="text/javascript">
	
import ColorPickerItem from "@/components/ColorPickerItem";

export default {
	components:{
		ColorPickerItem
	},
	props:{
    modelValue: String
  },
  emits: ['update:modelValue'],
data() {
return {
	colors:[
		{"name":"Red","code":"#FF8E8E"},
		{"name":"Orange","code":"#FFD68E"},
		{"name":"Light Blue","code":"#B1DAE7"},
		{"name":"Light Green","code":"#99FFDD"},
		{"name":"Pastel Green", "code":"#b2e6c4"},
		{"name":"Gray","code":"#C3CFDA"},
		{"name":"Dark Gray","code":"#3a3a3a"},
		{"name":"Dark Red","code":"#FB7776"},
		{"name":"Yellow","code":"#F6DB53"},
		{"name":"Spicy Orange","code":"#F6A34C"},
		{"name":"Green","code":"#5DCB83"},
		{"name":"Aqua","code":"#46CBBD"},
		{"name":"Sky Blue","code":"#58ABE8"},
		{"name":"Dark Blue","code":"#7192F1"},
		{"name":"Violet","code":"#AD88F0"},
		{"name":"Pink","code":"#F37CA9"},
		{"name":"White","code":"#FFFFFF"}
	],
	openPickerState:false,
	colorTitle:this.modelValue
	}
	},
	methods: {
	selectColor(color) {
		this.openPickerState = false;
		this.colorTitle = color.name;
		this.$emit('update:modelValue', color.code);
	},
	openColorPicker() {
		this.openPickerState = true;
	}
	}
 }

</script>

<style>
	.colorSelectorCurrent {
	width: 1.5em;
	height: 1.5em;
	border-radius: 0.25em;
	border: 1px solid #e8ece0;
	background-color: v-bind('modelValue');
	margin: 0.5em;
	}
	.colorSelectorCurrent:active {
		border-color: #2f4f4f;
	}
.colorSelector {
	width:auto;
}
</style>}
