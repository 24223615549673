import axios from "axios";
import store from '../store';

const instance = axios.create({
  baseURL: `https://papers.tools/api`,
  // baseURL: `http://localhost:8080/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
