<template>
	<div class="starItemBlock" :title="ratingKey" @click="setRating" @mouseover="updateHover" @mouseleave="relesaseHover" >
        <svg width="100%" height="100%" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" :class="hoveredClass" class="starRatingItem">
    <g transform="matrix(0.888042,0,0,0.888042,3.58266,3.21209)">
        <path d="M28.808,4.913C28.099,5.762 25.975,9.728 24.065,13.694L20.594,20.776L12.38,21.98C3.246,23.256 1.05,24.033 0.128,26.513C-0.368,27.999 0.341,28.99 6.571,35.365L13.584,42.514L12.24,49.948C11.46,54.054 10.965,58.658 11.036,60.215C11.25,62.621 11.531,63.12 13.302,63.757C15.074,64.395 16.278,63.971 23.57,60.076L31.855,55.686L39.856,59.934C48.565,64.538 50.689,64.962 52.247,62.482C53.166,61.067 53.166,59.934 51.751,51.506L50.265,42.158L57.133,35.572C61.737,31.182 64,28.562 64,27.5C64,24.667 61.167,23.395 51.819,22.048C47.076,21.339 43.11,20.772 43.038,20.704C42.967,20.633 41.267,17.023 39.286,12.772C37.233,8.452 35.109,4.557 34.471,4.062C32.632,2.718 30.223,3.071 28.808,4.913Z" style="fill-rule:nonzero;stroke-width:7.32px;"/>
    </g>
	</svg>

	</div>
</template>

<script>
	
export default {
	components:{
	},
	computed: {
		hoveredClass() {
			if(this.hovered) {
				if(this.selected) {
					return 'starItemSelected';
				}
				return 'starItemHovered'
			}
			return ''
		},
	},
	props:{
	ratingKey: Number,
    selected: Boolean,
    hovered: Boolean,
      bgColorDefault: {
        type: String,
        default:"#a7a8a8"
      }
	},
	data() {
		return {
			rating:0
			}
	},
	methods: {
		setRating() {
			this.$emit("ratingUpdated",this.ratingKey);
		},
		updateHover() {
			this.$emit("ratingHoverUpdated",this.ratingKey);
		},
		relesaseHover() {
			this.$emit("ratingHoverUpdated",-1);
		}

	}
 };

</script>
<style>
.starItemBlock {
	display: inline-block;
	width: 40px;
	height: 40px;
	margin-right: 0.5%;
}
.starRatingItem {

  transition: stroke 0.2s ease-out 50ms;
  transition: fill 0.2s ease-out 50ms;
}
.starRatingItem {
	fill:none;
	stroke:v-bind("bgColorDefault");
}
.starRatingItem:hover {
	stroke:#ffdf33;
}
.starItemSelected {
	stroke:#ffdf33;
	fill: #ffdf33;
}
.starItemHovered {
	stroke:#ffdf33;
}
.starItemUnselected {
	color:#000000;
}
</style>