<template>
	<div class="form-floating">
		<textarea :class="{'multiLayerField':multiLayer, 'errorInField':errorClass}" :title="placeholder" class="form-control" :id="textAreaId" :placeholder="placeholder" v-model="value" :maxlength="maxLength" @blur="onBlurM" @focus="onFocusM" @input="onInputM"></textarea>
		<label :for="textAreaId">{{this.dynPlaceholder}}</label>
<!-- 		<div class="sharingButton" v-show="copyClipboard">
      <i class="fa fa-solid fa-copy"></i>
    </div> -->
	</div>
</template>

<script>
	
	export default {
	components:{
		
	},
	computed: {
		textAreaId() {
			return `${this.placeholder}Form`;
		},
		// :class="dynPlaceholderClass" 
		theme() {
			return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
		},
		overflowBehavior() {
			if(this.multiLayer) {
				return 'auto'
			}
			return 'hidden'
		},
		labelSize() {
			if(this.value.length>0) {
				return '2em'
			}
				return '0'
		},
		// dynPlaceholderClass() {
		// 	if(this.textAreaValue.length==this.maxLength) {
		// 		return `labelFull`;
		// 	}
		// 	if(this.textAreaValue.length>=this.maxLength-this.warningLimit) {
		// 		return `labelWarning`;
		// 	}
		// 	return ``;
		// },
		dynPlaceholder() {
			if(this.errorMsg.length>0) {
				return this.errorMsg;
			}
			if(this.value.toString().length>0) {
				let symbolsLeft = this.maxLength-this.value.toString().length;
				symbolsLeft = ((symbolsLeft>0)?symbolsLeft:0);
				return `${this.placeholder} (${symbolsLeft} symbols left)`;
			}
				return this.placeholder;
		},
		value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
	},
	props:{
			modelValue: {},
      maxLength: {
        type: Number,
        default: 50
			},
      warningLimit: {
        type: Number,
        default: 10
			},
			errorClass:{},
			errorMsg:{type:String, default:""},
			placeholder: {},
			multiLayer:{type:Boolean, default:false},
			blurTrigger:{type:Boolean, default:false},
			copyClipboard:{type:Boolean, default:false}
	},
	emits: ['update:modelValue', 'blurevent', 'focusevent', 'inputevent'],
	data() {
		return {
		}
	},
	methods: {
		onBlurM() {
			if(this.blurTrigger) {
				this.$emit("blurevent");
			}
		},
		onFocusM() {
			if(this.errorMsg.length>0) {
				this.$emit("focusevent")
			}
		},
		onInputM() {
			this.$emit("inputevent")
		}
	}
}
</script>
<style scoped>
.sharingButton {
  border:1px solid red;
  height: 4em;
/*  margin-top: -2em;*/
  width:1em;
  top:0;
  padding-top: 1em;
  position: absolute;
  right:8px;
  opacity: 0.5;
}
.sharingButton:hover {
	opacity: 1;
}
.form-control {
}
.multiLayerField {
	min-height: 8em!important;
}
.form-floating .labelFull {
	color:#FB7776;
	opacity: 0.8!important;
}
.form-floating .labelWarning {
	color:#ff8c00;
	opacity: 0.8!important;
}
.form-control textarea{
	min-height: 6em;
}
.form-floating label {

  color:v-bind("theme.titleColor");
}

.form-floating:before {
  content: '';
  position: absolute;
  margin-top:1px;
  margin-left:1px;
  width: calc(100% - 14px); /* to show scrollbar */
  height: v-bind("labelSize");
  border-radius: 4px; /* (default by BS) */
  background-color: v-bind("theme.formBackground");
}

.form-floating textarea.form-control {
  background-color: v-bind("theme.formBackground");
  color:v-bind("theme.titleColor");
  resize: none;
  padding-top: 32px; /* height of pseudo element */
  min-height: 3em; /* not relevant */
  overflow-y: v-bind("overflowBehavior");
}
.errorInField {
  border-color: #cc0000;
}
</style>