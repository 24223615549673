<template>
	<div>
		<FileDragNDropForm v-if="attachedFiles.length==0" :apiUrl="apiUrl"
		@fileUploaded="fileUploaded"></FileDragNDropForm>
		<UploadedFileItem v-else :deleteApiUrl="deleteApiUrl" :downloadApiUrl="downloadApiUrl" :fileObject="attachedFiles" @fileDeleted="processFileDeleted"></UploadedFileItem>
	</div>
</template>

<script>
import FileDragNDropForm from "@/components/FileDragNDropForm";
import UploadedFileItem from "@/components/UploadedFileItem";
	
export default {
	props:['apiUrl','deleteApiUrl','attachedFiles','downloadApiUrl'],
	components:{
		FileDragNDropForm,
		UploadedFileItem
	},
	computed: {
	},
	data() {
		return {
			rating:3,
			}
	},
	methods: {

		uuidv4() {
			return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
			(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
			);
		},
		fileUploaded(resp) {
          let newFileMeta = {
            id:this.uuidv4(),
            filename:resp.filename,
            originalFilename:resp.originalFilename,
            uploadedAt:new Date().toISOString(),
            review:{},
          };
          if(resp.metaDataExtracted) {
						newFileMeta = {...newFileMeta, metaDataExtracted:true, metaDataTitle:resp.metaDataTitle, metaDataDoi:resp.metaDataDoi}
          }
          this.$emit("fileUploaded", newFileMeta);
		},
		processFileDeleted(fileParams) {
			this.$emit("fileDeleted",fileParams);
		}
	}
 };

</script>
