<template>
  <div class="cardListItem newPaperButton" v-if="!isPaperFormShowing" @click.prevent="handleNewPaper" title="Add Paper">
    <div class="cardListItem-body">
      <!-- New Item Link Markup Start -->
      <div class="text-center text-dark">
        <span>
<!--           <svg style="width:15px;height:10px" viewBox="0 0 24 24">
            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path>
          </svg> -->

          <strong><i class="fa fa-solid fa-paper-plane"></i> Add Paper</strong>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["list","disabledActions"],
  data() {
    return {
      isPaperFormShowing: false
    };
  },
  methods: {
    handleNewPaper() {
      if(this.disabledActions) {
        return
      }
      let newPaper = {
          id:-1,
          doi:"",
          title:"New Paper",
          color:"#ffffff",
          authors:[],
          keywords:[],
          important:false,
          favourite:false,
          read:false,
          created:"",
          tags:[],
          comment:"",
          review:"",
          link:"",
          publisher:"",
          publishedYear:0,
          publishedCity:"",
          publishedCountry:"",
          rating:0,
          recommended:false,
          attachedFiles: [],
          issn:"",
          abstract:"",
          summary:"",
          language:"",
          publishedJournal:"",
          references:[],
          notificationDate:""
        }
      this.$emit("paper-created", newPaper);
    }
  }
};
</script>

<style scope>
  .newPaperButton {
    opacity: 0.6;
    background-color: #ffffff;
  }
  .newPaperButton:hover {
    opacity: 1;
    background-color: #ebebeb;
    cursor: default;
  }
</style>

