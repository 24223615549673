
<template>
  <div class="mainapp homeViewRoot">
    <div class="toolbar" v-if="ready" v-show="disableAccessIfWithKey">
      <SearchBox class="searchBoxC toolbarTool" v-model="searchQuery" :searchBoxOpen="searchBoxOpen" @click="openSearchDialog"></SearchBox>
      <div class="toolsIcons">
        <div @click="openClosePaperBin" class="toolIcon toolbarTool" :class="{ toolIconSelected:this.paperBinOpened }" title="Paper Bin"><i class="fa-solid fa-bucket" ></i></div>

        <div @click="openSettingBoardboard" class="toolIcon toolbarTool" :class="{ toolIconSelected:this.boardSettingsOpen }" title="Board Settings"><i class="fa-solid fa-gear" ></i></div>
      </div>
    </div>
    <header v-if="ready">
    <h2>
<!-- <input class="editableContent editableContentInput" v-model="boardData.title" :maxlength="this.$store.state.globalSettings.maxBoardNameLength"  @blur="saveBoardChanges" @keypress.enter="saveBoardChanges" :disabled="!disableAccessIfWithKey" title="Click to change title"> -->
<TextAreaWIthError v-model="boardData.title" :maxFieldLength="this.$store.state.globalSettings.maxBoardNameLength" @blured="saveBoardChanges" title="Click to change title" :disabledFlag="!disableAccessIfWithKey"></TextAreaWIthError>
    </h2>
    <!-- :style="{width:titleInputWidthV}" @input="adjustTitleInput" -->

<TextAreaWIthError class="boardDescription" v-model="boardData.description" :maxFieldLength="this.$store.state.globalSettings.maxBoardDescLength" @blured="saveBoardChanges" title="Click to change description" :disabledFlag="!disableAccessIfWithKey"></TextAreaWIthError>

<!-- <input class="boardDescription editableContent editableContentInput" :maxlength="this.$store.state.globalSettings.maxBoardDescLength" v-model="boardData.description"  @blur="saveBoardChanges" @keypress.enter="saveBoardChanges" :disabled="!disableAccessIfWithKey" title="Click to change description"> -->
<!-- :style="{width:descriptionInputWidthV}" @input="adjustDescInput" -->
  </header>
    <div class="scrolling-wrapper">
      <draggable
        :list="this.lists"
        :disabled="categoryEditing"
        v-bind="dragOptionsCategory"
        group="category"
        draggable=".draggableCategory"
        @start="drag=true"
        @end="drag=false,submitBoard()"
        class="row flex-nowrap mt-1"
        style="width:100%"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list-category' : null">
        <CategoriesList
          class="draggableCategory"
          v-for="(listItem, index) in lists"
          :key="index"
          :list="listItem"
          :searchQuery="this.searchQuery"
          item-text="New Category"
          :paperEditingState="false"
          :paperBinState="paperBinOpened"
          :paperBinInit="paperBinInit"
          :editorState="editorOpeningState"
          :disabledFlag="categoryEditing"
          :canAddPaper="canAddPaper"
          @cat-deleted="catDeleted"
          @group-deleted="groupDeleted"
          @paper-editing="paperEdit"
          @categories-updated="submitBoard"
          @category-editing="editCategory"
          @category-save="saveCategory"
          @category-cancel="releaseCategory"
          @paperBinClosed="openClosePaperBin"
        ></CategoriesList>
      </transition-group>
        
          <div class="addCategory col-3 list-width" :draggable="false" @click="addNewBoard" role="group" key="footer" slot="footer">
            <div class="addCategoryHeader heading">

            </div>
            <div class="addCategoryContent">
            <div class="addCategoryIcon">
            <!-- + -->
            <i class="fa-regular fa-plus-square fa-10x" aria-hidden="true" :title="titleAddCategory"></i>
            </div>
            <div class="addCategoryText">{{titleAddCategory}}</div>
            </div>
          </div>
      </draggable>
  </div>
  <div class="boardSettingsMenu" v-show="boardSettingsOpen">
  <BoardSettings
  :currentBoard="this.boardData"
  @boardUpdated="this.saveBoardChanges"
  @boardDeleted="this.openAlert"></BoardSettings>
  </div>
  <div class="paperEditor animate__animated" :class="{animate__fadeInRight:this.editorOpeningState, animate__fadeOutRight:this.editorClosingState, initPaperEditor:!this.editorUsed}">
  <PaperMetaEditor
  :="{currentPaper}"
  :apiUrl="apiUrl"
  :boardId="$route.params.boardId"
  @paper-saved="savePaper"
  @paper-cancelled="cancelEditing"
  @paper-deleted="deletePaper"
  ></PaperMetaEditor>
  </div>
  <div :class="{editorCurtainOpen:this.$store.state.globalSettings.showSettingCurtain, editorCurtainClosed:!this.$store.state.globalSettings.showSettingCurtain}" class="editorCurtain" @click="closeCurtain">
  </div>
  <ErrorAlert
    v-show="showErrorMessage"
    :alertMessage="this.alertMessage"
    :errorMessageClass="this.errorMessageClass"
    @closeErrorMessage="this.showErrorMessage=false"
    >
  </ErrorAlert>
  <div class="alertMessage" v-show="showAlert">
  <CustomAlert
    :alertMessage="this.alertMessageText"
    @alertRejectMethod="cancelBoardDeleting"
    @alertAcceptMethod="deleteBoard"
  >
  </CustomAlert>
  </div>
</div>
</template>

<script>
// const serverUrl = "https://paperpusherappbackend.ey.r.appspot.com"
// const serverUrl = "http://localhost:8080"
// const url = `${serverUrl}/api/boards`;
// const updateUrl = `${serverUrl}/api/boards/update`;
import axios from 'axios';
import AddCategory from "@/components/AddCategory";
import SearchBox from "@/components/SearchBox";
import ErrorAlert from "@/components/ErrorAlert";
import CategoriesList from "@/components/CategoriesList";
import PaperMetaEditor from "@/components/PaperMetaEdit";
import BoardSettings from "@/components/BoardSettings";
import CustomAlert from "@/components/CustomAlert";
import TextAreaWIthError from "@/components/TextAreaWIthError";
// import { dataset } from '../data.js';
const dataste = null;
import { VueDraggableNext } from 'vue-draggable-next';
import 'animate.css';
import RawView from "@/components/rawview"


import authHeader from '../services/auth-header';

export default {

  name: "MainAppBoard",
  components: {
    draggable: VueDraggableNext,
    // AddCategory,
    CategoriesList,
    PaperMetaEditor,
    SearchBox,
    ErrorAlert,
    BoardSettings,
    CustomAlert,
    TextAreaWIthError,
    // RawView
  },
  data() {
    return {
      searchBoxOpen:false,
      titleInputWidth:0,
      descriptionInputWidth:0,
      searchQuery:{query:"", caseSensitive:false, searchActive:false},
      // fulldata: JSON.parse(JSON.stringify(dataset)),
      lists: null,
      drag:false,
      paperBinOpened:false,
      paperBinInit:false,
      boardData : null,
      paperEditing:false,
      showAlert:false,
      boardSettingsOpen:false,
      alertMessageText:"",
      errorMessageClass:"alert-danger",
      alertMessage:"",
      showErrorMessage:false,
      ready:false,
      currentPaper:
         {
          doi:"",
          color:"#ffffff",
          title:"",
          authors:[],
          keywords:[],
          important:false,
          favourite:false,
          read:false,
          created:"",
          tags:[],
          comment:"",
          review:"",
          link:"",
          publisher:"",
          publishedYear:0,
          publishedCity:"",
          publishedCountry:"",
          rating:0,
          recommended:false,
          attachedFiles: [],
          issn:"",
          abstract:"",
          summary:"",
          language:"",
          publishedJournal:"",
          references:[],
          notificationDate:""
        },
        currentPaperTemp:{},
        groupid:-1, catid:-1,
        editorOpeningState:false,editorClosingState:false,
        editorUsed:false, editingState:{state:false},
        categoryEditing:false
    };
  },
  watch: {
    // lists: function (v) {
    //   console.log(this.lists);
    // },
  },
  created: function () {
    let urlExt = '';
    if(this.$route.query.accessKey) {
      urlExt = `?accessKey=${this.$route.query.accessKey}`;
    }
    let fullUrl = `${this.mainServerUrl}/${this.$route.params.boardId}`+urlExt;


    axios.get(fullUrl, { headers:authHeader()}).then(res=> {
          this.boardData=res.data;
          this.lists=this.boardData['content'];
          this.ready=true;
          this.adjustTitleInput();
          this.adjustDescInput();
        })
        .catch(err=>{
          this.showErrorMessage=true;
          this.$store.state.globalSettings.iconState="ERROR";
          if(err.response) {
              let status = err.response.status;
              if(status==403) {
                this.alertMessage=err.response.data.message;
              }
              else {
                this.alertMessage="Unknown error";
              }
          }
          else {
            this.alertMessage="Unknown error";
          }
        })


      // fetch(fullUrl,{headers:authHeader()})
      //   .then(r => {
      //       if(r.status==200) {
      //         r.json().then(json=>{
      //                   this.boardData=json;
      //                   this.lists=this.boardData['content'];
      //                   this.ready=true;
      //                   this.adjustTitleInput();
      //                   this.adjustDescInput();
      //                   });
      //       }
      //       else {
      //         r.json().then(json=>{
      //                   this.alertMessage=json.message;
      //                   this.showErrorMessage=true;
      //                   })
      //         .catch(()=>{
      //                   this.alertMessage="Auth error";
      //                   this.showErrorMessage=true;})
      //       }})
      //   .catch(err=>{console.log(err)});
    },
  computed: {
    countPapers() {
      let papersCount = 0;
      if(!this.boardData)
        return 0;
      JSON.stringify(this.boardData, (_, nestedValue) => {
              if (nestedValue && nestedValue["papers"]) {
                papersCount += nestedValue.papers.length;
              }
              return nestedValue;
            });
      return papersCount;
    },
    titleAddCategory() {
      if(this.canAddPaper) {
        return "Add Category";
      }
      return "Limit of papers reached";
    },
    canAddPaper() {
      if(this.currentUser && this.$store.state.globalSettings.proFuncStatus.includes(this.currentUser.status))
        return true;
      return this.$store.state.globalSettings.maxFreePapers-this.countPapers>0;
    },
    theme() {
      return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
    },
    titleInputWidthV() {
      return (this.titleInputWidth + 1 ) + 'ch'
    },
    descriptionInputWidthV() {
      return (this.descriptionInputWidth + 1 ) + 'ch'
    },
    disableAccessIfWithKey() {
        if(this.$route.query.accessKey && this.$route.query.accessKey.length>0) {
            return false
        }
        return true
    },
    bgColor() {
        if(this.boardData) {
          return this.boardData.background;
        }
        return "#ffffff";
    },
    mainServerUrl() {
        return `${this.$store.state.globalSettings.backendUrl}/api/boards`;
    },
    updateServerUrl() {
        return `${this.$store.state.globalSettings.backendUrl}/api/boards/update`;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    userExpirationToken() {
      if(this.currentUser) {
        return Date.parse(this.currentUser.expirationToken) > Date.now(); 
      }
      return false;
    },
    dragOptionsCategory() {
      return {
        animation: 800,
        group: "category",
        disabled: this.categoryEditing,
        ghostClass: "ghostCategory"
      };
    },
    apiUrl() {
      return `${this.$store.state.globalSettings.backendUrl}/api`
    },
    shouldAllowListOrder() {
      return this.isDesktop || this.isTablet;
    },
    getDragHandle() {
      return this.isMobile ? "" : ".heading";
    },
    paperEditorLeftPosition() {
      let myscreen = screen || window;
      if(this.isMobileOrTablet && myscreen.orientation.type=="portrait-primary") {
        return "calc(0+min(27vw,400px))";
      }
      return "0";
    },

    accessKeyExt() {
      let urlExt = '';
      if(this.$route.query.accessKey) {
        urlExt = `&accessKey=${this.$route.query.accessKey}`;
      }
      return urlExt;
      }
  },
  methods:{
    openSearchDialog() {
      this.searchBoxOpen=true;
      this.$store.state.globalSettings.showSettingCurtainFunction="search"
      this.$store.state.globalSettings.showSettingCurtain=true;
    },
    openSettingBoardboard() {
      this.boardSettingsOpen=true;
      this.$store.state.globalSettings.showSettingCurtainFunction="board";
      this.$store.state.globalSettings.showSettingCurtain=true;
    },
    adjustTitleInput() {
      this.titleInputWidth = this.boardData.title.length
    },
    adjustDescInput() {
      this.descriptionInputWidth = this.boardData.description.length
    },
        saveBoardChanges() {
            this.submitBoard({"item":"board"});
        },
        openAlert() {
          this.alertMessageText="Please confirm deletion of the board";
          this.showAlert=true;
        },
        deleteBoard() {
          this.boardData.deleted=true;
          this.showAlert=false;
          this.submitBoard({"item":"board", "redirect":"/profile"});
        },
        cancelBoardDeleting() {
          this.$store.state.globalSettings.showSettingCurtain=true;
          this.showAlert=false;
        },
        uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
        },
        addNewFile() {
          let newFileMeta = {
            id:this.uuidv4(),
            filename:"",
            originalFilename:"",
            uploadedAt:"",
            review:{},
          };
          this.currentPaper['attachedFiles'].push(newFileMeta);
        },
        openClosePaperBin() {
          this.paperBinOpened = !this.paperBinOpened;
          this.paperBinInit = true;
        },

        clog() {
          console.log('id' + (new Date()).getTime())
        },
        submitBoard(delta) {
          if(this.$store.state.globalSettings.savingError) {
            this.$store.state.globalSettings.iconState="ERROR";
            return;
          }

          this.$store.state.globalSettings.iconState="SUBMITTING";
          let boardDataToSubmit = {};
          let wasEditing = false;
          if(this.currentPaperTemp["isEditingPaper"]) {
            wasEditing = true;
            delete this.currentPaper["isEditingPaper"];
            delete this.currentPaperTemp["isEditingPaper"];

          }
          // boardDataToSubmit = {...this.boardData}
          boardDataToSubmit = JSON.parse(JSON.stringify(this.boardData))
          if(wasEditing) {
            this.currentPaperTemp["isEditingPaper"] = true;
            this.currentPaper["isEditingPaper"] = true;

          }
            if(boardDataToSubmit!=null) {
            fetch(`${this.updateServerUrl}/${boardDataToSubmit._id}?accessKey=${this.$route.query.accessKey}`, {
                method: 'PUT',
                headers: {...authHeader(), ...{'Content-Type': 'application/json'}},
                body: JSON.stringify(boardDataToSubmit)
              }).then(r => {
                  let minStatusChangeTime = this.$store.state.globalSettings.minStatusChangeTime;
                 if(r.status!=200) { 
                    setTimeout(()=>{this.$store.state.globalSettings.iconState="ERROR";},minStatusChangeTime);
                    
                    r.json().then(json=>{ this.alertMessage=json.message;this.showErrorMessage=true;
                     })}
                    else {
                    setTimeout(()=>{this.$store.state.globalSettings.iconState="DEFAULT";}, minStatusChangeTime);
                      if(delta && delta.redirect) {
                          this.$router.push(delta.redirect);
                      }
                    }
                })
            }
        },
        checkMaxTitle(){
          
        },
        editCategory() {
          this.categoryEditing = true;
        },
        releaseCategory() {
          this.categoryEditing = false;
        },
        saveCategory() {
          this.releaseCategory();
        },
        closeCurtain() { 
        let curFunc = this.$store.state.globalSettings.showSettingCurtainFunction;
          if(curFunc=="paper") {
            this.savePaper(this.currentPaperTemp);
          }
          if(curFunc=="board") {
            this.saveBoardChanges();
            this.boardSettingsOpen=false;
          }
          if(curFunc=="search") {
            this.searchBoxOpen=false;
          }
          if(curFunc=="profile") {
            this.$store.state.globalSettings.userMenuOpen = false;
          }
          this.$store.state.globalSettings.showSettingCurtainFunction=false;
          this.$store.state.globalSettings.showSettingCurtain=false;
        },
        savePaper(paper, saveTemp=false) {

          if(this.currentPaperTemp.newPaper) {
            delete this.currentPaperTemp.newPaper
          }
          for(const property in this.currentPaperTemp) {
            this.currentPaperTemp[property] = this.currentPaper[property];
          }
          if(!saveTemp) {
          delete this.currentPaperTemp.isEditingPaper;
          this.closeForm();
          }


          this.submitBoard({"item":"paper","newItem":paper,"paper":paper.id,"group":this.groupid, "category":this.catid,"action":"edit"});
        },
        closeForm() {
          this.$store.state.globalSettings.showSettingCurtainFunction=false;
          this.$store.state.globalSettings.showSettingCurtain=false;
          this.editorOpeningState = false;
          this.editorClosingState = true;
          this.editingState.state = false;
        },
        clearForm() {
          for(const property in this.currentPaper) {
              this.currentPaper[property] = "";
              if(property=="authors" || property=="tags") {

              this.currentPaper[property] = [];
              }
          }
          delete this.currentPaper.isEditingPaper;
        },
        deletePaperFiles(paper) {
          let deleteApiUrl = `${this.apiUrl}/deleteFile?boardId=${this.$route.params.boardId}`
          for(let fileI=0; fileI<paper.attachedFiles.length;fileI++) {
              let deleteUrl = `${deleteApiUrl}&fileName=${paper.attachedFiles[fileI].filename}`+this.accessKeyExt;
                fetch(deleteUrl,{
                method: 'GET',
                headers:authHeader()
                });
          }

        },
        deletePaper() {
          let catid = this.lists.findIndex(t => t.id == this.catid);
          let groupid = this.lists[catid].items.findIndex(t => t.id == this.groupid);
          let paperid = this.lists[catid].items[groupid].papers.findIndex(t => t.id == this.currentPaperTemp.id);
          if(this.groupid!="deletedpapers") {
            let delcatid = this.lists.findIndex(t => t.id == "paperbin");
            let delgroupid = this.lists[delcatid].items.findIndex(t => t.id == "deletedpapers");
            // let newPaperId = 0;
            // if(this.lists[delcatid].items[delgroupid].papers.length>0) {
            //   newPaperId = Math.max(...this.lists[delcatid].items[delgroupid].papers.map(o => o.id)) + 1
            // }
            let paper = this.lists[catid].items[groupid].papers[paperid];
            // paper.id = newPaperId;

            delete paper.isEditingPaper;
            this.lists[delcatid].items[delgroupid].papers.push(paper);
          }
          else {
            this.deletePaperFiles(this.lists[catid].items[groupid].papers[paperid]);
          }
          delete this.currentPaperTemp.isEditingPaper;
          this.lists[catid].items[groupid].papers.splice(paperid,1);
          this.clearForm();
          this.closeForm();
          this.submitBoard({"item":"paper","paper":paperid,"group":groupid, "category":catid,"action":"delete"});
        },
        cancelEditing() {
          for(const property in this.currentPaperTemp) {
              this.currentPaper[property] = this.currentPaperTemp[property];
          }
          if(this.currentPaper.newPaper) {
            this.deletePaper()
          }
          delete this.currentPaperTemp.isEditingPaper;
          this.closeForm();
        },
        paperEdit(paper, groupid, catid) {
          this.$store.state.globalSettings.showSettingCurtainFunction="paper";
          this.$store.state.globalSettings.showSettingCurtain=true;
          if(this.currentPaperTemp.isEditingPaper) {
            delete this.currentPaperTemp.isEditingPaper;
          }
          this.groupid = groupid;
          this.catid = catid;
          this.currentPaper = { ...paper };
          // this.currentPaper = paper;
          paper.isEditingPaper = true;
          this.currentPaperTemp = paper;
          this.editorOpeningState = true;
          this.editorClosingState = false;
          this.editorUsed = true;
        },
        openEditor() {
          if(this.paperEditing ) {
            this.paperEditing = false;
          }
          else {
            this.paperEditing = true;
          }
        },
        addNewBoard: function(){
        if(this.categoryEditing || !this.canAddPaper) {
          return;
        }
        const id =  this.lists.length;
        let newCategoryId = this.uuidv4();
        // if(this.lists.length>0) {
        //  newCategoryId = Math.max(...(this.lists.filter(e=>e.id!="paperbin")).map(o => o.id)) + 1;
        // }
        let newCategory = {
            id:newCategoryId,
            name: "newcategory",
            description: "",
            headerColor: "#B1DAE7",
            items: []
          };
        this.lists.push(newCategory);
          this.submitBoard({"item":"category","newItem":newCategory,"action":"create","group":-1});
      },
          getFullBoard: function() {
            this.lists[0].items[0].papers[0].show=true;
          },
          groupDeleted:function(catIdUID, groupid) {
              let catid = this.lists.findIndex(t => t.id == catIdUID);
              let delcatid = this.lists.findIndex(t => t.id == "paperbin");
              let delgroupid = this.lists[delcatid].items.findIndex(t => t.id == "deletedpapers");
              let paperBin = this.lists[delcatid].items[delgroupid].papers;
              let groupData = this.lists[catid].items[groupid];
              paperBin.push.apply(paperBin, groupData.papers);
          },
          catDeleted:function(item) {
          let itid = this.lists.findIndex(t => t.id == item);
          let categoryData = this.lists[itid];
          if(categoryData.items && categoryData.items.length > 0) {
              for(let iGroup = 0; iGroup < categoryData.items.length; iGroup++) {
                  this.groupDeleted(item, iGroup);
              }
          }
          this.lists.splice(itid,1);
          this.submitBoard({"item":"category","id":itid,"action":"delete"});
          }
    }
};
</script>
<style>

.alertMessage {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}
  .boardSettingsMenu {
  top:3em;
  right: 5vw;
  background-color: white;
  position: absolute;
  box-shadow: 1px 1px 1px 0px rgba(158, 158, 158, 0.25);
  border: 1px solid #ebebeb;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  z-index:10;
  }
  .boardDescription input {
    width: 80vw;
    font-size: 0.75em;
    font-weight: lighter;
    color:v-bind("theme.descColor");
    padding: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  .editableContentInput:hover {
    box-shadow: none;
    border: 0;
    outline:none;
    outline-width: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  .editableContentInput:focus {

  box-shadow: none;
/*  border-color: #ebebeb;*/
/*border:1px solid #ebebeb;*/
    outline:none;
    outline-width: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  .editableContentInput {
    color:v-bind("theme.titleColor");
  border: 0;
    width:80vw;
  }
  .editableContent:focus {
    outline: 1px solid #aaa;
  }
  .editableContent {
      background-color: v-bind("background");
      padding: 0.15em 0.5em 0.15em 0.25em;
  }
  .editableContent:hover {
    background-color: v-bind("theme.formBackgroundHover");
  }
  .toolbarTool {
    display: inline-block;
  }
  .addCategoryContent {
    color: v-bind("theme.descColor");
    margin-top:25vh;
  }
  .addCategoryHeader {
    min-height: 3.5em;
    border-bottom:4px dashed #777777;
  }
  .addCategory:hover {
    opacity: 1;
  }
  .addCategory {
    opacity: 0.5;
    border:4px dashed #777777;
  }
.editorCurtainOpen {
  display: block;
}
.editorCurtainClosed {
  display: none;
}
.editorCurtain {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  top:0;
  z-index: 2;
}
.toolbar {
  width-min:4em;
  position: absolute;
  top:0.25em;
  right:10vw;
  height:auto;
}
.toolsIcons {
  display: inline-flex;
  margin-left: 0.75em;
}
.toolIcon {
  display: inline-flex;
  font-size: 1.25em;
/*  width: auto;*/
  min-width: 1.9em;
/*  min-height: 1em;*/
  margin-left: 0.1em;
  margin-right: 0.05em;
  border-radius: 1em;
  padding: 0.4em;
  border: 1px solid;
  border-color: rgba(48,130,156,0);
}
.toolIcon:hover {
  background-color: rgba(48,130,156,0.7);
  border-color: rgba(48,130,156,1);
}
.toolIcon:active {
  border-color: #00cccc;
}
.toolIconSelected {
  background-color: #246175;
}



.no-move {
  transition: transform 0s;
}
.paperEditor {
  z-index: 3;
  position: fixed;
  top:0;
  border-left:2px solid rgb(96, 125, 139);
  min-width: 400px;
  right:v-bind("paperEditorLeftPosition")
}

.initPaperEditor {
  transform: translate3d(100%, 0, 0);
}
.addCard:hover {
  opacity: 1;
}
.addCard {
  opacity: 0.5;
/*  padding-left:5vw;*/
/*  padding-top:15%;*/
  left:5em;
  float:right;
  width:10em;
}

  .addCategoryText {
    text-align: center;
  }
  .addCategoryIcon {
    text-align: center;
/*    font-size: 8em;*/
  }
.scrolling-wrapper {
  overflow-x: touch;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}
body {
  overflow-x: hidden;
}
.mainapp {
  padding-left: 1em;
  padding-right: 1em;
  background-color: v-bind("bgColor");
    padding-top: 0.5em;
    padding-bottom: 3.5em;
/*  scrollbar-color: auto transparent;*/
}
.flip-list-move-category {
  transition: transform 2s;
}
.ghostCategory {
  opacity: 0.5;
  background: #c8ebfb;
}
.searchBoxC {
  position: relative;
/*  max-width:15vw;*/
  bottom: 0.25em;
}
</style>



