<template>
  <div id="app" class="container-fluid">
    <NavHeader></NavHeader>
    <!-- <MainAppBoard></MainAppBoard> -->
    <!-- <TestView></TestView> -->
    <router-view></router-view>
    <NavFooter></NavFooter>

  <div :style="showAppCurtain" class="globalAppCurtain" @click="closeAppCurtain">
  </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router';
import NavHeader from "@/components/NavHeader";
import NavFooter from "@/components/NavFooter";
import TestView from "@/components/TestView";
import EventBus from "./common/EventBus";
// import MainAppBoard from "@/components/MainAppBoard";
export default {
  name: "App",

  computed: {
    theme() {
      return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
    },
    showAppCurtain() {
      if(this.$store.state.globalSettings.showAppCurtain) {
        return "display:block"
      }
      return ""
    },
  },
  components: {
    NavHeader,
    NavFooter,
    // TestView
    // MainAppBoard
  },
  data() {
    return {
    };
  },
  methods: {
    closeAppCurtain() {
        let curFunc = this.$store.state.globalSettings.showAppCurtainFunction;
          if(curFunc=="profile") {
            this.$store.state.globalSettings.userMenuOpen = false;
          }
          this.$store.state.globalSettings.showAppCurtainFunction=false;
          this.$store.state.globalSettings.showAppCurtain=false;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  },
};
</script>

<style>
  .globalAppCurtain {
    display: none;
    position: fixed;
    margin: 0;
    width: 100%;
    height: 100%;
    top:0;
    z-index: 2;
  }
  #app {
    padding-left:0;
    padding-right:0;
  }
body {
  color:v-bind("theme.titleColor")!important;
  background-color: v-bind("theme.background")!important;
  display:flex; flex-direction:column;
}
</style>
