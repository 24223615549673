<template>
	<div class="settingsBox" v-if="currentBoard">
		<div>Background: <div id="boardBGPicker"><ColorPicker v-model="currentBoard.background"></ColorPicker></div></div>
		<div>Access Key enabled 
		<div style="display: inline-block;">
		<label class="switch">
			<input type="checkbox" v-model="currentBoard.accessKeyAllowed">
			<span class="slider round"></span>
		</label>
		</div>
		<HintTooltip :hint="{text:'If enabled this board could be shared with another user'}"></HintTooltip>
		</div>
		<div v-show="currentBoard.accessKeyAllowed">Access Key <HintTooltip :hint="{text:'Access key could be used to share this board'}"></HintTooltip></div>
		<div v-show="currentBoard.accessKeyAllowed" class="form-floating">


    <TextAreaNotification :copyClipboard="true" v-model="currentBoard.accessKey" :disabled="!currentBoard.accessKeyAllowed" :errorMsg="accessKeyErrorMsg" placeholder="Access key" :maxLength="16" @inputevent="checkBoardKey" @blurevent="checkBoardKey" :class="{'errorInputField':accessKeyFormatError}"/>
		</div>
		<div class="form-floating">Keywords <HintTooltip :hint="{text:'Keywords that describe the board'}"></HintTooltip>
		<div id="tagsList">
			<draggable
				:list="currentBoard.tags"
				group="BoardTagsList"
				@start="drag=true"
				@end="drag=false"
				>
			<InteractiveTag 
				v-for="(listItem, index) in currentBoard.tags"
				:key="index"
				:list="listItem"
				@tag-deleted="boardTagDeleted">
			</InteractiveTag>
			</draggable>
        
			<div class="input-group mb-3">


  <TextAreaNotification v-model="newBoardTag" :errorMsg="this.newBoardTagError" placeholder="New board tag" :maxLength="50" @keypress.enter.prevent="addNewBoardTag" @focusevent="clearNewTagError" @blurevent="addNewBoardTag" blurTrigger></TextAreaNotification>

			</div>

		</div>
		</div>

		
          <PaperToolsButton
            buttonTitle="Delete board"
            buttonIcon="fa-xmark"
            buttonStyle="cancel"
            @click.prevent="deleteBoard"
          >
          </PaperToolsButton> 
    <div class="sharingButton" v-show="canCopyUrl" title="Copy url" @click="copyBoardUrl">
      <i class="fa fa-solid fa-copy"></i>
    </div>
    <div class="sharingButton" v-show="currentBoard.content" title="Export" @click="exportJSON">
      <i class="fa-solid fa-download"></i>
    </div>
    
	</div>
</template>
<script>
import TextAreaNotification from "@/components/TextAreaNotification";
import InteractiveTag from "@/components/InteractiveTag";
import HintTooltip from "@/components/HintTooltip";
import ColorPicker from "@/components/ColorPicker";
import { VueDraggableNext } from 'vue-draggable-next';
import PaperToolsButton from '@/components/mainView/PaperToolsButton';
const { v4: uuidv4 } = require('uuid');

export default {
  components: {
    InteractiveTag,
    HintTooltip,
    draggable: VueDraggableNext,
    ColorPicker,
    PaperToolsButton,
    TextAreaNotification,
  },
  name: "raw-displayer",
  props:["currentBoard"],
  data() {
	return {
		newBoardTag:"",
		newBoardTagError:"",
		bgColor:"",
    accessKeyFormatError:false,
    accessKeyErrorMsg:"",
	}
  },
  computed: {
    canCopyUrl() {
      if(this.currentBoard.accessKeyAllowed && !this.accessKeyFormatError) {
        return true
      }
      return false
    },
    theme() {
      return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
    },
  },
  methods: {


   exportData(exportObj, exportName, format){
    let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  },
  exportJSON() {
    this.exportData(this.currentBoard.content, "mydumpexport", "json");
  },
  checkBoardKey() {
    let alLRegEx = /^[0-9a-zA-Z]+$/;
    if(this.currentBoard.accessKey.length==0) {
      this.accessKeyFormatError=false;
      this.accessKeyErrorMsg="";
      this.$store.state.globalSettings.savingError=false;
      return;
    }
    if(!this.currentBoard.accessKey.match(alLRegEx)) {
      this.accessKeyFormatError=true;
      this.accessKeyErrorMsg="Only alphanumeric symbols";``
      this.$store.state.globalSettings.savingError=true;
    }
    else {
      this.accessKeyFormatError=false;
      this.accessKeyErrorMsg="";
      this.$store.state.globalSettings.savingError=false;
    }
  },
	deleteBoard() {
    this.$store.state.globalSettings.showSettingCurtain=false;
		this.$emit("boardDeleted");
	},
  clearNewBoardTagForm() {
    this.newBoardTag = ""
  },
  clearNewTagError() {
		this.newBoardTagError = "";
  },
	addNewBoardTag(){
    const minTagNameLength = 1;
    let newTag = this.newBoardTag.toString().trim();
    if(newTag.length>0) {
      if(newTag.length<minTagNameLength) {
        this.newBoardTagError = "Too short tag name";
        return;
      }
      if(this.currentBoard.tags.filter(e=>e.name.toLowerCase()==newTag.toLowerCase()).length>0) {
        this.newBoardTagError = "Tag already exists";
        return;
      }
      if(newTag.length>=minTagNameLength) {
        let new_item = {id:uuidv4(), name:newTag};
        this.currentBoard.tags.push(new_item);
        this.clearNewBoardTagForm();
      }
		}
	},
    boardTagDeleted(item){
      let itid = this.currentBoard.tags.findIndex(t => t.id == item.id);
      this.currentBoard.tags.splice(itid,1)
    },
    copyBoardUrl() {
      let boardUrl = `https://papers.tools/board/${this.$route.params.boardId}?accessKey=${this.currentBoard.accessKey}`;
      navigator.clipboard.writeText(boardUrl);
    }
  }
}

</script>

<style scoped>
.sharingButton {
  border: 1px solid;
  border-radius: 0.5em;
  padding:0.5em;
  display: inline;
  margin-left: .5em;
  font-size: 1.25em;
  opacity: 0.5;
  transition: opacity 0.2s;
  cursor: pointer;
}
.sharingButton:hover {
  opacity: 1;
}
.inputErrorMessage {
  position: absolute;
  background-color: v-bind("theme.tooltipBackgrond");
  color:v-bind("theme.formBackgroundHover");
  font-size: 0.5em;
  box-sizing: border-box;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  z-index: 4;
}
.errorInputField
{
  border:1px solid  #cc0000!important;
}
#boardBGPicker {
	display: inline-block;
	top: 0.75em;
	position: relative;
}
.settingsBox {
	min-width: 15em;
	width: auto;
	height: auto;
	padding: 1em;
}
 /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #3ca2c3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3ca2c3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
} 
</style>