<template>
	<div>
		<!-- <UploadedFileManager :attachedFiles="currentPaper.attachedFiles" apiUrl="http://localhost:8080/api/uploadFile?boardId=63bb0cf2e588e51937b9b9a1" deleteApiUrl="http://localhost:8080/api/deleteFile?boardId=63bb0cf2e588e51937b9b9a1" downloadApiUrl="http://localhost:8080/api/getFile?boardId=63bb0cf2e588e51937b9b9a1" @fileUploaded="fileUploaded" @fileDeleted="fileDeleted"></UploadedFileManager> -->
		{{err}}
		<TextAreaWIthError v-model="textv"></TextAreaWIthError>
	</div>
</template>

<script>

import TextAreaWIthError from "@/components/TextAreaWIthError";
export default {
	components:{
		// UploadedFileManager
		TextAreaWIthError
	},
	data() {
		return {
			textv:"",
			err:false
			}
	},
	methods: {
		fileUploaded(newFile) {
			this.currentPaper.attachedFiles.push(newFile);
		},
		fileDeleted(fileParams) {
			this.currentPaper.attachedFiles = []
		}
	}
 };

</script>
