<template>
	<div class="hintTooltip">
		<div>
		<!-- <i class="fa-solid fa-xmark fa-2x" ></i> -->
		<!-- <i class="fa-solid fa-circle-question"></i> -->
		<span class="hintTooltipIcon" title="Hint" @mouseover="tooltipHidden=true" @mouseleave="tooltipHidden=false">?</span>
		</div>
		<div class="hintTooltipText" v-show="tooltipHidden">
			{{hint.text}}
		</div>
	</div>
</template>
<script>
	
export default {
	props:["hint"],
	data(){
		return {
			tooltipHidden: false
		}
	},
  }

</script>

<style scoped>

.hintTooltip {
	display: inline-block;
	margin-left: 0.25em;
}
.hintTooltipIcon {
    display: block;
    background: #aaa;
    color: #fff;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    line-height: 1em;
    text-align: center;
    font-size: 1em;
/*    font-family: Verdana;*/
	transition: background-color 0.2s;
}
.hintTooltipIcon:hover {
    background: #555;
}
.hintTooltipIconHidden {
/*	display: none;*/
opacity: 1;
}
.hintTooltipText {
	position: absolute;
	max-width: 20em;
	min-width: 10em;
	min-height: 5em;
	background-color: #555;
	z-index: 10;
	color:#ffffff;
	padding: 1em;
	font-size: 0.75em;
}
</style>