<template>
  <div class="newGroupButton card" v-if="!isFormShowing" @click.prevent="handleNew">
    <div class="card-body newGroupButtonBody">
      <!-- New Item Link Markup Start -->
      <div class="text-center">
        <span>
<!--           <svg style="width:15px;height:15px" viewBox="0 0 24 24">
            <path fill="#000000" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"></path>
          </svg> -->
          <i class="fa fa-regular fa-plus-square fa-2x"></i><br>
          <strong>Add group</strong>
        </span>
      </div>
    </div>
  </div>
  <div class="card" v-else>
    <div class="card-body newCard">
      <!-- Item Display Markup End -->
      <!-- Form Markup Start -->
      <div class="form">
        <div class="form-group">
          <!-- <textarea rows="3" class="form-control" v-model="form.text"></textarea> -->

        <TextAreaNotification @keypress.enter.prevent="" class="groupTitleEditField" style="z-index:1;position: relative;" v-model="form.text" placeholder="Category title" :maxLength="this.$store.state.globalSettings.maxGroupNameLength"></TextAreaNotification>
        </div>
        <span class="colorPickerGroupLabel">Color:</span>
        <div class="colorPickerGroupForm">
        <ColorPicker v-model="form.color">
        </ColorPicker>
        </div>
        <div class="form-group text-center formSaveButtons">
          <button class="btn btn-outline-primary btn-sm mr-2" @click.prevent="save">Save</button>
          <button class="btn btn-outline-secondary btn-sm" @click.prevent="cancel">Cancel</button>
        </div>
      </div>
      <!-- Form Markup End -->
    </div>
  </div>
</template>
<script>
import ColorPicker from "@/components/ColorPicker";
import TextAreaNotification from "@/components/TextAreaNotification";
export default {
  components: {
    ColorPicker,
    TextAreaNotification
  },
  props: ["disabledActions"],
  data() {
    return {
      form: {
        id: "",
        text: "",
        form: "",
        color:"#ffffff"
      },
      isFormShowing: false
    };
  },
  methods: {
    handleNew() {
      if(this.disabledActions) {
        return;
      }
        this.isFormShowing = true;
        this.$emit("item-creating");
    },
    clearForm() {
      this.form.text = "";
    },
    save() {
      const item = { id: -1, text: this.form.text,papers:[], color:this.form.color };
      this.clearForm();
      this.isFormShowing = false;
      this.$emit("item-created", item);
    },
    cancel() {
      this.clearForm();
      this.isFormShowing = false;
      this.$emit("item-cancelled");
    }
  }
};
</script>

<style>
  .groupTitleEditField textarea {
  min-height: 6em!important;
}
  .newGroupButton {
    color:#000000;
    background-color: rgb(255,255,255);
  }
  .newGroupButtonBody {
    border-radius: 5px;
    opacity: 0.5;
  }
  .newGroupButtonBody:hover {
    opacity: 1;
    cursor: default;
    background-color: #ebebeb;
  }
  .newCard {
    background-color: v-bind('form.color');;
  }
</style>

