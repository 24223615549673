<template>
  <div class="errorAlert">
    <div class="alert" :class="errorMessageClass" role="alert">
      {{ alertMessage }}
      <div class="errorAlertButton">
      <button type="button" id="refreshButton" class="btn btn-warning" @click="refreshPage"><i class="fa-solid fa-arrows-rotate"></i> Refresh</button>
      <button type="button" class="btn btn-danger" @click="closeErrorMessage"><i class="fa-solid fa-xmark"></i> Close</button>
    </div>
    </div>
  </div>
</template>
<script>
  
export default {

  name: "MainAppBoard",
  props: ["alertMessage","errorMessageClass"],
  methods: {
        closeErrorMessage() {
          this.$emit("closeErrorMessage")
        },
        refreshPage() {
          location.reload();
        },
  }
}

</script>
<style scoped>
  .errorAlert {
/*border:1px solid #3ca2c3;*/
top:50%;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
position: absolute;
width:40%;
min-width:300px;
min-height: 5vh;
background: #ffffff;
}
.errorAlertHeader {
  min-height: 2em;
  background-color: #B1DAE7;
  color:#ffffff;
}
.errorAlertContent {
  min-height: 4em;
}
.errorAlertButton {
  min-height: 2em;
  padding-bottom:0.5em;
  padding-top:1em;
  text-align: center;
}
.errorAlertButton button {
  margin-left:1em;
  margin-right:1em;
  border-radius:0.5em;
  padding:0.25em 1em 0.25em 1em;
}

</style>