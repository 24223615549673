import Vue from "vue";
// import Vuesax from 'vuesax3'
import { createApp } from 'vue';
import App from './App.vue';
import platform from './plugins/platform'
import router from './router'
import store from './store';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';


import setupInterceptors from './services/setupInterceptors';

library.add(fas, far, fab)
dom.watch();
const app = createApp(App);
app.use(platform);
app.use(router);
app.use(store);

window.vueApp = app;

app.mount("#app");
