<template>
  <div class="">
    Logging Out
  </div>
</template>

<script>

export default {
  name: "LogOutView",
  mounted() {
    
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
  }
};
</script>
