<template>
  <div class="homeViewRoot">
      Support Page
</div>
</template>

<script>

export default {
  name: "HomeView",
  components: {
  },
  data() {
    return {
      
    };
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
    },
  },
  methods: {
    signin() {
      this.userMenuOpen=false;
      this.$router.push('/login');
    },
    signup() {
      this.userMenuOpen=false;
      this.$router.push('/register');
    }

  },
  mounted() {
    // UserService.getPublicContent().then(
    //   (response) => {
    //     this.content = response.data;
    //   },
    //   (error) => {
    //     this.content =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();
    //   }
    // );
  },
};
</script>
<style>
  </style>