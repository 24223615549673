<template>
  <div class="cardListItem">

    <div class="cardListItem-body paperBody" :class="{paperEditing:list.isEditingPaper, hiddenBySearch:checkSearchHiddenStatus}" @click="handleEditPaper">
      <!-- Display Item Markup Start -->
      <div>
        <span class="paperItem text-style disable-select">
          <span class="statusIcon notificationIcon" v-show="showNotification" title="Notification">
            <i class="fa-solid fa-bell"></i>
          </span>
          <span v-show="list.important" class="statusIcon importantIcon" title="Important">
            <!-- ❗️ -->
            <i class="fa-solid fa-triangle-exclamation"></i>
            <!-- <i class="fa-solid fa-exclamation" aria-hidden="true"></i> -->
          </span> 
          <span v-show="list.favourite" class="statusIcon favouriteIcon" title="Favourite">
            <i class="fa fa-heart" aria-hidden="true"></i>
            <!-- ❤️ -->
          </span>

          <span v-show="list.read" class="statusIcon readIcon" title="Read">
            <!-- <i class="fa-solid fa-book-bookmark" aria-hidden="true"></i> -->
            <i class="fa-regular fa-circle-check"></i>
            <!-- 📖 -->
          </span>
<span v-if="list.authors.length>0" class="firstAuthor">{{list.authors[0].name}}</span> <span v-if="list.publishedYear>0" class="publicationYear">({{list.publishedYear}})</span>  <span class="publicationTitle">
{{list.title}}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import paperSpecialStatus from "@/components/paperSpecialStatus";
export default {
  components: {
  },
  props: ["list","searchQuery"],
    data() {
    return {
    };
  },
  computed: {
    showNotification() {
      let now = new Date();
      if(this.list.notificationDate=="" || this.list.notificationDate>now || !this.list.notificationDate) {
        return false;
      }
      return true;
    },
    shouldUseDragHandlePaper() {
      return this.isDesktop ? false : true;
    },
    contentColor(){
      let prc = 10;
      let hex = this.list.color
      hex = hex.replace(/^\s*#|\s*$/g, '');
      if(hex.length == 3){
          hex = hex.replace(/(.)/g, '$1$1');
      }
      const mult = 0.9;
      var r = parseInt(hex.substr(0, 2), 16),
          g = parseInt(hex.substr(2, 2), 16),
          b = parseInt(hex.substr(4, 2), 16);
        return `rgb(${Math.round(r*mult)},${Math.round(g*mult)},${Math.round(b*mult)})`;
      },
    headerColor() {
      var c = this.list.color.substring(1);      // strip #
      var rgb = parseInt(c, 16);   // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff;  // extract red
      var g = (rgb >>  8) & 0xff;  // extract green
      var b = (rgb >>  0) & 0xff;  // extract blue
      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      if (luma < 150) {
          return "#ffffff";
      }
      else {
          return "#000000";
      }
    },
    checkSearchHiddenStatus() {
      let q = this.searchQuery;
      if(q) {
        if(this.searchQuery.searchActive) {
          if(this.searchQuery.caseSensitive) {
            if(!this.list.title.includes(this.searchQuery.query))
              { return true }
          }
          else { 
            if(!this.list.title.toLowerCase().includes(this.searchQuery.query.toLowerCase()))
              { return true }
          }
        }
      }
      return false
    }
  },
  methods: {
    handleEditPaper() {
      this.$emit("paper-editing", this.list);
    }
  }
};
</script>

<style scope>
.notificationIcon {
/*  color:;*/
}
.hiddenBySearch {
  opacity: 0.05;
}
.publicationTitle {
  font-weight: 500;
}
.firstAuthor {
  color:#777777;
  font-weight: lighter;
}
.publicationYear {
  color:#777777;
  font-weight: lighter;
}
.paperBody {
  cursor: move;
  background-color: v-bind('list.color');
  overflow-x: hidden;
  transition: opacity 0.4s;
}
.paperBody:hover, .paperBody:active {
  background-color:v-bind('contentColor')
}
.cardListItem {
  min-height: 20px;
  border-bottom: 0.01rem solid rgba(0, 0, 0, 0.9);
  font-size: 13px;
  background-color: rgba(255, 255, 255, 0.85);
}
.cardListItem-body {
  padding: 1rem !important;
}
.cardListItem:hover,.cardListItem:active {
  background: rgba(0, 0, 0, 0.02);
}

span.text-style {
  white-space: normal;
  padding-left: 10px;
}

.drag-handle {
  position: absolute;
  top: 15px;
  left: 7px;
  margin-right: 5px;
  cursor: pointer;
}

.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.paperEditing {
  background-color: #d8ecf3;
}
.important {
  color:red;
}
.statusShown {
  display: inline;
}
.statusHidden {
  display: none;
}
.statusIcon {
/*  maring-left: 0.2em;*/
  margin-right: 0.2em;
  font-size: 1.75em;
  opacity: 0.8;
}
.statusIcon:hover {
  opacity: 1;
}
.importantIcon {
  color:#ffc107;
}
.favouriteIcon {
  color:#dc3545;;
}
.readIcon {
  color:#198754;;
}
.paperItem {
  color:v-bind("headerColor");
  padding-left:0;
  overflow-x: hidden;

}
</style>

