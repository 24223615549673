<template>
  <div class="col-3 list-width categoryItem" v-if="!this.list.paperbin">

    <div class="heading" :style="{ backgroundColor: this.list.headerColor,color:headerColor }">

      <div v-show="!categoryEditing">
      <h4 class="text-center" v-if="this.list.description.length==0">[Unnamed category]</h4>
      <h4 class="text-center" v-else>{{ this.list.description }}</h4>
          <div class="editCategoryIcon" @click="handleEdit" title="Rename Category">
          <svg viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M20.71,4.04C21.1,3.65 21.1,3 20.71,2.63L18.37,0.29C18,-0.1 17.35,-0.1 16.96,0.29L15,2.25L18.75,6M17.75,7L14,3.25L4,13.25V17H7.75L17.75,7Z"
            ></path>
          </svg>
          </div>
          <div class="deleteCategoryIcon" @click="deleteCategory" title="Remove Category">
          <svg viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M 22.413 1.585 C 23.195 2.365 23.196 3.631 22.415 4.413 L 21.852 4.977 L 14.829 12 L 22.414 19.586 C 23.195 20.367 23.195 21.633 22.414 22.414 C 21.633 23.195 20.367 23.195 19.586 22.414 L 12 14.828 L 4.414 22.414 C 3.633 23.195 2.367 23.195 1.586 22.414 C 0.805 21.633 0.805 20.367 1.586 19.586 L 9.172 12 L 2.149 4.977 L 1.587 4.415 C 0.805 3.635 0.804 2.369 1.585 1.587 C 2.365 0.805 3.631 0.804 4.413 1.585 L 4.977 2.148 L 12.001 9.172 L 19.023 2.149 L 19.585 1.587 C 20.365 0.805 21.631 0.804 22.413 1.585 Z"
            ></path>
          </svg>
          </div>
        </div>
      <div v-show="categoryEditing" class="form">
        <div class="form-group">
          <!-- <textarea @keypress.enter.prevent="" class="form-control" v-model="this.categoryEditingData.description"></textarea> -->

        <TextAreaNotification @keypress.enter.prevent="" class="categoryTitleEditField" style="z-index:1;position: relative;" v-model="this.categoryEditingData.description" placeholder="Category title" :maxLength="this.$store.state.globalSettings.maxCategoryNameLength"></TextAreaNotification>
        </div>
        <div class="colorPickerGroup">
        <span class="colorPickerGroupLabel">Color:</span>
        <div class="colorPickerForm">
        <ColorPicker v-model="this.list.headerColor">
        </ColorPicker>
        </div>
        </div>
        <div class="form-group text-center formSaveButtons">
          <button class="btn btn-outline-primary btn-sm mr-2" :style="{ color:headerColor }" @click.prevent="saveCategory">Save</button>
          <button class="btn btn-outline-secondary btn-sm" :style="{ color:headerColor }" @click.prevent="cancelEditing">Cancel</button>
        </div>
      </div>
    </div>
    <div class="cards cards-list" :data-type="this.list.name">
      <draggable
        :list="this.list.items"
        :group="categoryGroupName"
        @start="dragGroup=true"
        @end="dragGroup=false,movedGroups()"
        v-bind="dragOptions"
      >
        <transition-group type="transition" :name="!dragGroup ? 'flip-list-group' : null">
        <CardEdit
          v-for="(item,index) in this.list.items"
          :item="item"
          :key="index"
          :disabledFlag="disabledFlag"
          :canAddPaper="canAddPaper"
          defaultpapertext="New paper"
          :paperEditingState="paperEditingState"
          :searchQuery="searchQuery"
          @item-edited="itemEdited"
          @item-cancelled="itemCancelled"
          @item-editing="itemEditing"
          @item-deleted="itemDeleted"
          @paper-editing="paperEditing"
          @group-updated="groupUpdated"
        ></CardEdit>
    </transition-group>
      </draggable>
      <CardNew
        class="fixed-card"
        v-show="canAddGroup"
        :disabledActions="disabledFlag"
        @item-creating="itemEditing"
        @item-created="itemCreated"
        @item-cancelled="itemCancelled"
      ></CardNew>
    </div>

  </div>

  <div v-else  class="col-3 list-width paperBinView animate__animated" :class="{animate__fadeInRight:this.paperBinState, animate__fadeOutRight:!this.paperBinState, initPaperEditor:!this.paperBinInit}">
    <div class="heading" :style="{ backgroundColor: this.list.headerColor,color:headerColor }" style="max-width: 90vw;">
      <h4 class="text-center"><i class="fa-solid fa-bucket"></i> {{ this.list.description }}</h4>
      <div class="deleteCategoryIcon" @click="closePaperBin" title="Close Paper Bin">
          <svg style="width:15px;height:15px" viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M 22.413 1.585 C 23.195 2.365 23.196 3.631 22.415 4.413 L 21.852 4.977 L 14.829 12 L 22.414 19.586 C 23.195 20.367 23.195 21.633 22.414 22.414 C 21.633 23.195 20.367 23.195 19.586 22.414 L 12 14.828 L 4.414 22.414 C 3.633 23.195 2.367 23.195 1.586 22.414 C 0.805 21.633 0.805 20.367 1.586 19.586 L 9.172 12 L 2.149 4.977 L 1.587 4.415 C 0.805 3.635 0.804 2.369 1.585 1.587 C 2.365 0.805 3.631 0.804 4.413 1.585 L 4.977 2.148 L 12.001 9.172 L 19.023 2.149 L 19.585 1.587 C 20.365 0.805 21.631 0.804 22.413 1.585 Z"
            ></path>
          </svg>
          </div>

      <div id="binList" class="cards cards-list" :data-type="this.list.name">
            <draggable
        :list="this.list.items"
        :disabled="true"
        group="categoryGroupNameBin"
        >
          <CardEdit
            v-for="(item,index) in this.list.items"
            :item="item"
            :key="index"
            defaultpapertext="New paper"
            :paperEditingState="paperEditingState"
            @item-edited="itemEdited"
            @item-cancelled="itemCancelled"
            @item-editing="itemEditing"
            @item-deleted="itemDeleted"
            @paper-editing="paperEditing"
            @group-updated="groupUpdated"
          ></CardEdit>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import CardNew from "@/components/CardNew";
import CardEdit from "@/components/CardEdit";
import ColorPicker from "@/components/ColorPicker";
import TextAreaNotification from "@/components/TextAreaNotification";
import { VueDraggableNext } from 'vue-draggable-next';
export default {
  components: {
    CardNew,
    CardEdit,
    ColorPicker,
    draggable: VueDraggableNext,
    TextAreaNotification
  },
  props: ["list", "itemText", "paperEditingState", "editorState", "paperBinState", "paperBinInit", "searchQuery", "disabledFlag","canAddPaper"],

  data() {
    return {
      dragGroup:false,
      paperBinOpenState:false,
      paperBinClosingState:false,
      paperBinOpened:false,
      isEditing: false,
      categoryEditing:false,
      categoryEditingData:
      {
          id:this.list.id,
          name: this.list.name,
          description:this.list.description,
          headerColor: this.list.headerColor
        }
    };
  },

  computed: {
    canAddGroup() {
      if(!this.list.paperbin && this.canAddPaper) {
        return true;
      }
      return false;
    },
    dragOptions() {
      return {
        animation: 500,
        group: "paperGroup",
        disabled: this.disabledFlag,
        ghostClass: "ghostGroup"
      };
    },
    moveAllowed() {
      return this.isEditing;
    },
    categoryGroupName() {
      if(this.list.paperbin) {
        return "groupBin"
      }
      else {
        return "paperGroup"
      }
    },
    defaultItem() {
      return { id: 0, text: this.itemText };
    },
    headerColor() {
      var c = this.list.headerColor.substring(1);      // strip #
      var rgb = parseInt(c, 16);   // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff;  // extract red
      var g = (rgb >>  8) & 0xff;  // extract green
      var b = (rgb >>  0) & 0xff;  // extract blue
      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      if (luma < 150) {
          return "#ffffff";
      }
      else {
          return "#000000";
      }
    },
    contentColor(){
      let prc = 10;
      let mult = 1.4;
      let hex = this.list.headerColor
      hex = hex.replace(/^\s*#|\s*$/g, '');
      if(hex.length == 3){
          hex = hex.replace(/(.)/g, '$1$1');
      }
      var r = parseInt(hex.substr(0, 2), 16),
          g = parseInt(hex.substr(2, 2), 16),
          b = parseInt(hex.substr(4, 2), 16);
        return `rgba(${Math.round(r*mult)},${Math.round(g*mult)},${Math.round(b*mult)},1)`;
      },
    },

  methods: {
    uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
    },
    movedGroups() {
      this.$emit("categories-updated",{"item":"category","newItem":this.categoryEditingData,"category":this.list.id,"action":"moved"});
    },
    groupUpdated(paper) {
      paper["category"] = this.list.id;
      this.$emit("categories-updated", paper);
    },
    handleEdit() {
      if(!this.disabledFlag) {
      this.categoryEditingData.id=this.list.id;
      this.categoryEditingData.name=this.list.name;
      this.categoryEditingData.description=this.list.description;
      this.categoryEditingData.headerColor=this.list.headerColor;
      this.categoryEditing = true;
      this.$emit("category-editing");
      }
    },
    saveCategory() {
      this.list.description = this.categoryEditingData.description;
      this.categoryEditing = false;
      this.list.name = this.list.description.toLowerCase().replace(/\W+/g, '');
      this.$emit("categories-updated",{"item":"category","newItem":this.categoryEditingData,"category":this.list.id,"action":"edit"});
      this.$emit("category-save");
    },
    cancelEditing() {
      this.categoryEditingData.description = this.list.description;
      this.categoryEditing = false;
      this.list.headerColor = this.categoryEditingData.headerColor;
      this.$emit("category-cancel");
    },
    itemEditing() {
      this.isEditing = true;
      this.$emit("category-editing");
    },
    itemEdited(item) {
      const itm = this.list.items.find(t => t.id == item.id);
      itm.list = item.list;
      itm.text = item.text;
      itm.color = item.color;
      this.isEditing = false;
      this.$emit("categories-updated",{"item":"group","newItem":itm,"group":itm.id, "category":this.list.id,"action":"edit"});
      this.$emit("category-cancel");
    },
    itemCreating() {
      this.isEditing = true;
    },
    itemCreated(item) {
      // let newCategoryId = 0;
      // if(this.list.items.length>0) {
      //   newCategoryId = Math.max(...this.list.items.map(o => o.id)) + 1
      // }
      item.id = this.uuidv4();
      this.list.items.push(item);
      this.isEditing = false;
      this.$emit("category-cancel");
      this.$emit("categories-updated",{"item":"group","newItem":item,"category":this.list.id,"group":-1,"action":"create"});
    },
    itemCancelled() {
      this.isEditing = false;
      this.categoryEditing = false;
      this.$emit("category-cancel");
    },
    itemDeleted: function(item){
      let itid = this.list.items.findIndex(t => t.id == item.id);
      this.$emit("group-deleted", this.list.id, itid)
      this.list.items.splice(itid,1);
      this.$emit("categories-updated",{"item":"group","group":itid,"category":this.list.id,"action":"delete"});
    },
    deleteCategory: function(item) {
      if(!this.disabledFlag)
      this.$emit("cat-deleted", this.list.id);
    },
    closePaperBin: function() {
      this.$emit("paperBinClosed");
    },
    paperEditing: function(paper, groupid) {
      this.$emit("paper-editing", paper, groupid, this.list.id);
    }
  }
};
</script>

<style>
.categoryTitleEditField textarea {
  min-height: 6em!important;
}
.newCardButton {
  opacity: 0.5;
}
.paperBinView {
  position: absolute;
  margin-right: 0!important;
  right: 0;
  z-index: 3;

/*  position: fixed;*/
/*  top:0;*/
/*  right:0;*/
/*  border-left:2px solid rgb(96, 125, 139);*/
}
#binList {
  background-color: #bbbbbb;
}
.heading:hover {

  cursor: move;
}
.heading h4 {
  margin-bottom: 0;
  font-size: 1.275rem;
}
.heading:hover div.editCategoryIcon,.heading:hover div.deleteCategoryIcon {
  display: block;
}
.editCategoryIcon {
  position: absolute;
  top: 5px;
  right: 33px;
  display: none;
  cursor: pointer;
  opacity: 0.5;
  color:v-bind("headerColor");
}
.editCategoryIcon:hover {
  opacity: 1;
}
.editCategoryIcon svg path{
  fill:v-bind("headerColor");
}
.deleteCategoryIcon svg path{
  fill:v-bind("headerColor");
}
.deleteCategoryIcon {
  color:v-bind("headerColor");
  position: absolute;
  top: 3px;
  right: 15px;
  display: none;
  cursor: pointer;
  opacity: 0.5;
}
.deleteCategoryIcon svg {
  width:15px;height:15px
}
.editCategoryIcon svg {
  width:15px;height:15px
}
@media (max-width: 700px) { 
  .deleteCategoryIcon, .editCategoryIcon  {
  display: block;
}
  .deleteCategoryIcon svg, .editCategoryIcon svg {
    width:24px;
    height: 24px;
  }
  .editCategoryIcon {
    right:50px!important;
  }
}
.deleteCategoryIcon:hover {
  opacity: 1;
}
.col-3 {
  padding: 0 !important;
  margin: 0 15px;
}
.heading {
  min-height: 3em;
  position: relative;
  padding: 10px 10px;
  color: v-bind("headerColor"); 
  border-top-left-radius: 2em;
  border-top-right-radius: 2em;
}

.cards-list {
  min-height: 300px;
  height: 100vh;
  overflow-y: auto;
/*  box-shadow: 1px 1px 1px 0px rgba(158, 158, 158, 0.25);*/
  background-color: v-bind('contentColor');
  padding-bottom: 5em;
/*  rgba(223, 238, 242, 0.4);*/
}

.fixed-card {
/*  color: #ccc;*/
  border: 1px dotted #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.list-width {
  min-width: min(400px, 90vw);
  max-width: min(400px, 90vw);
  border-radius: 10px;
}
@media (max-width: 700px) { 

.list-width {
  min-width: min(350px, 90vw);
  max-width: min(350px, 90vw);
}
}
.colorPickerGroup {

  margin-top: -2em;
  position: absolute;
  z-index: 2;
  left: 1em;
}
.colorPickerForm {
  width:80%;
  left:4em;
  background-color: #ffffff;
  margin-top: -2em;
  z-index: 2;
  position: relative;
}
.formSaveButtons { 
  padding-top: 0.5em
  }
.initPaperEditor {
  transform: translate3d(100%, 0, 0);
}
.flip-list-move-group {
  transition: transform 0.5s;
}
.ghostGroup {
  opacity: 0.5;
  background: #c8ebfb;
}
.categoryItem {
  box-shadow: 1px 1px 1px 1px rgba(158, 158, 158, 0.25);
  border: 1px solid rgba(158, 158, 158, 0.25);
  border-radius: 2em;
}
</style>


