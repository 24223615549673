<template>
<div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>
  <div class="carousel-inner">
    <div class="carouselContent carousel-item active" data-bs-interval="10000">
      <div class="d-none d-sm-block carouselContentImage carouselContentImage2"></div>
      <div class="carousel-caption">
        <div>
          <h5><p class="mainDesc">P<span id="aLetterSymbol">a</span>perTools is an innovative platform to keep your papers organized</p></h5>
        
        </div>
        <p class="carouseCaptionDesc d-none d-sm-block">Reading, reviewing or writing papers? Everything you need for your paper work experience is now under a single platform! PaperTools provides opportunities to build your paper work pipelines as never before!</p>
      </div>
    </div>

    <div class="carouselContent carousel-item" data-bs-interval="10000">
      <div class="carouselContentImage carouselContentImage1"></div>
      <div class="carousel-caption">
        <h5>Keep your collections organized</h5>
        <p class="carouseCaptionDesc">Kanban-like collection provides opportunities to simplify management of the papers library</p>
      </div>
    </div>

  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
</template>
<script>

</script>
<style lang="scss" scoped>
  #aLetterSymbol {
    width:2em;
    padding-right: 0.5em;
  background-position-y: center;
  background-image: url("@/assets/plane_def.svg");
  background-size: 1em;
  background-repeat: no-repeat;
  color: transparent;
/*  text-indent: 0;*/
/*  display: block;*/
/*  line-height: initial;*/
  /* New content takes up original line height */
}
  #aLetterPlane {
    position: absolute;
    width:1em;
    top:1.5em;
/*    left:0em;*/
/*    margin-left:-0.25em;*/
/*    margin-right:-0.25em;*/
  }
  .carouselContentImage1{
    background-image: url('~@/assets/images/sample_0.png');
  }

  .carouselContentImage2{
    background-image: url('~@/assets/plane_fly.svg');
    background-size: 25%!important;
    margin-left: 20%;
    opacity: 0.5;
  }
</style>
<style>
.carousel-caption {
  top:1em;
  z-index: 10!important;
}
.carouseCaptionDesc {
  font-size: 0.9em;
}
.carouselContent {
  min-height: 300px;
}
.carouselContentImage {
  z-index: 0;
  top:0;
  background-size: 100%;
  background-repeat: no-repeat;
  min-height: 300px;
  min-width: 50vw;
  position: absolute;
  overflow: hidden;
}
</style>