<template>
	<div class="inlineButton" :class="buttonStyleClass" :title="buttonTitle">
		<i v-if="buttonIcon" class="fa-solid" :class="buttonIcon"></i> {{buttonTitle}}
	</div>
</template>
<script>
	
	export default {
  name: 'ProfileView',
  props:["buttonTitle", "buttonStyle", "buttonIcon"],
  computed: {
	buttonStyleClass() {
		if(this.buttonStyle=="cancel") {
			return "cancelButton"
		}
		return "defaultButton"
	}
  }
};
</script>
<style scoped>
  .inlineButton {
      cursor: pointer;
      display: inline-block;
      margin-top:1em;
      margin-left: 2em;
      padding: 0.5em;
      border-radius: 1em;
	box-shadow: 1px 0px 1px 1px rgba(192, 192, 192, 0.25);
      transition: background-color 0.15s;
  }
   .defaultButton {
      border:4px solid #B1DAE7;
  }
  .defaultButton:hover {
    background-color: #B1DAE7;
  }
  .cancelButton {
      border:4px solid #FB7776;
  }
  .cancelButton:hover {
      background-color: #FB7776;
  }
</style>