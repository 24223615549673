<template>
  <div class="homeViewRoot">
    <div class="homeHeader">
      <CarouselView :cards="homeViewCarouselCards"/>

</div>
  <div class="mainHomeContent">

  <h2>Versions</h2>
<table class="versionComparisonTable">
    <tr class="tableHeader"><th class="firstTableCol">Level</th><th>Free<UserStatusFlag status="test"></UserStatusFlag></th><th> Pro<UserStatusFlag status="pro"></UserStatusFlag></th></tr>
    <tr><td class="firstTableCol">Available</td><td>Always free</td><td>Annual subscription</td></tr>
    <tr><td class="firstTableCol">Sharing options</td><td class="verTableCol">&#10004;</td><td class="verTableCol">&#10004;</td></tr>
    <tr><td class="firstTableCol">Max boards</td><td class="verTableCol">10</td><td class="verTableCol">&infin;</td></tr>
    <tr><td class="firstTableCol">Max papers per board</td><td class="verTableCol">50</td><td class="verTableCol">&infin;</td></tr>
    <tr><td class="firstTableCol">Max file size</td><td class="verTableCol">1 MB</td><td class="verTableCol">10 MB</td></tr>
    <tr><td class="firstTableCol">Smart auto-fill</td><td class="verTableCol">-</td><td class="verTableCol">&#10004;</td></tr>
    <tr><td class="firstTableCol">Recover deleted board</td><td class="verTableCol">-</td><td class="verTableCol">Up-to 1 month</td></tr>
    <tr><td class="firstTableCol">PDF reviewing</td><td class="verTableCol">-</td><td class="verTableCol">+</td></tr>
    <tr><td class="firstTableCol">Export data</td><td class="verTableCol">JSON</td><td class="verTableCol">JSON, XML, PDF table</td></tr>
    <tr><td class="firstTableCol">Overleaf integration</td><td class="verTableCol">-</td><td class="verTableCol">&#10004;</td></tr>
    <tr><td class="firstTableCol">GitHub integration</td><td class="verTableCol">-</td><td class="verTableCol">&#10004;</td></tr>
    <tr><td class="firstTableCol">Advanced filtering</td><td class="verTableCol">Limited</td><td class="verTableCol">&#10004;</td></tr>
    <tr><td class="firstTableCol">Browser extension</td><td class="verTableCol">-</td><td class="verTableCol">&#10004;</td></tr>
    <tr><td class="firstTableCol">Notes</td><td class="verTableCol">Currently, Free(test) account has some features of the Pro subscription</td><td class="verTableCol">Not yet available</td></tr>

</table>
</div>
</div>
</template>

<script>
import UserService from "../../services/user.service";
import UserStatusFlag from '@/components/mainView/UserStatusFlag';
import PaperToolsButton from '@/components/mainView/PaperToolsButton';
import CarouselView from '@/components/mainView/CarouselView';

export default {
  name: "HomeView",
  components: {
    UserStatusFlag,
    // PaperToolsButton,
    CarouselView
  },
  data() {
    return {
      content: "",
      homeViewCarouselCards:[
        { id:0, 
          desc:" Reading lists, reviewing or writing papers? Everything you need for your improved paper work experience is now under a single platform! PaperTools provides integration opportunities to build your paper work pipelines as never before!",
            mainView:true},
        { id:1, 
          desc:"Kanban-like dashboard provides opportunities to simplify management of the papers library",
          image:"sample_0.png"},
        { id:2,
        desc:'Main features of PaperTools:',
        list:['Kabnan-like library','Smart auto-fill system to add new papers','Customisation of working boards','Collaboration with colleagues','Cloud-platform accessible from different devices'
          ],
        listView:true}
        ]
    };
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
    },
  },
  methods: {
    signin() {
      this.userMenuOpen=false;
      this.$router.push('/login');
    },
    signup() {
      this.userMenuOpen=false;
      this.$router.push('/register');
    }

  },
  mounted() {
    // UserService.getPublicContent().then(
    //   (response) => {
    //     this.content = response.data;
    //   },
    //   (error) => {
    //     this.content =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();
    //   }
    // );
  },
};
</script>
<style>
  table {

  table-layout: auto;
  }
  .versionComparisonTable {
    display: block;
/*    left:25%;*/
  -webkit-box-align:center;
  -webkit-box-pack:center;
  display:-webkit-box;
    right: 0;
    position: relative;
  }
.tableHeader {
  background-color: v-bind("theme.mainColor");
  font-size: 1.25em;
  text-align: center;
}
.tableHeader th {
  font-weight: lighter;
}
.firstTableCol {
  color:v-bind("theme.descColor");
  text-align: right;
  font-size: 1.25em;
  font-weight: lighter;
}
td {
  text-align: center;
  padding-top:0.5em;
  padding-bottom: 0.5em;
  max-width: 20em;
  font-size:1em;
  color:v-bind("theme.ratingBgColorDefault");
}
tr{
  position: relative;
  background-color: v-bind("theme.background");
  transition: background-color 0.15s;
}
tr:not(.tableHeader):hover {
  background-color: #ebebeb;
}
.mainHomeContent {
/*  height: 80vh;*/

/*  overflow: auto;*/
/*  padding:2em;*/
  margin-top: 2em;
  left:0;
  right: 0;
  position: relative;
/*  border: 1px solid #ccc;*/
/*  display: none;*/
/*  width: 100%;*/
/*  display: flex;*/
}
.sampleImage img {
  box-sizing: border-box;
  width: 100%;
}
.sampleImage {
  padding: 1em;
  width: 80%;
}
  .mainDescBlockFeats {
    font-weight: lighter;
    border: 1px solid #ccc;
    padding: 1em;
    border-radius: 1em;
    margin-left: 4em;
    top:0;
    font-size: 1.2em;
  }
  .mainDescBlock {
    top:0;
    min-height: 15em;
    display: inline-block;
    width: 45%;
  }
.homeViewRoot {
  min-height: 100vh;
  padding: 2em;
}
  </style>
