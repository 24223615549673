<template>
	<div>
		<StarRatingItem
		v-show="maxStars>0"
        v-for="(index) in maxStars"
        :ratingKey="index"
        :selected="index<=modelValue?true:false"
        :hovered="index<=this.hovered?true:false"
        :key="index"
        :bgColorDefault="bgColorDefault"
        @ratingUpdated="updateRating"
        @ratingHoverUpdated="updateHoveredRating"
		></StarRatingItem>
	</div>
</template>

<script>
import StarRatingItem from "@/components/StarRatingItem";
	
export default {
	components:{
		StarRatingItem
	},
	props:[
		"modelValue",
		"maxStars",
		"bgColorDefault"
	],
	emits: ['update:modelValue'],
	data() {
		return {
			hovered:this.modelValue
		}
	},
	methods: {
		updateRating(value) {
			this.$emit('update:modelValue', value);
		},
		updateHoveredRating(valueHovered) {
			if(valueHovered==-1) {
				this.hovered = this.modelValue;
			}
			else {
				this.hovered = valueHovered;
			}
		}
	}
}

</script>