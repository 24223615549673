<template>
	<div class="form-floating">
		<input class="editableContent editableContentInput" @focus="setFocus" @blur="setBlur"  type="text" :disabled="disabledFlag"  v-model="value" :maxlength="maxFieldLength" :title="title" @keypress.enter="setBlur">
		<div v-show="showErrorMessage" class="inputErrorMessage">{{errorMessage}}</div>
	</div>
</template>

<script>
	// :class="{'errorInputField':errorInputFlag}" 
	export default {
	components:{
		
	},
	computed: {
		theme() {
			return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
		},
		errorMessage() {
			if(this.errorType=="length") {
				if(this.errorInputFlag) {
					let symbolsLeft = this.maxFieldLength-this.value.toString().length;
					symbolsLeft = ((symbolsLeft>0)?symbolsLeft:0);
					return `${symbolsLeft} symbols left`;
				}
				return ''
			}
			return ''
		},
		errorInputFlag() {
			if(this.errorType=="length") {
				if(this.value.length>this.maxFieldLength-this.warningLimit) {
					return true;
				}
				return false;
			}
			return false;
		},
		showErrorMessage() {
			if(this.focused && this.errorInputFlag) {
				return true;
			}
			return false;
		},
		value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
	},
	props:{
			title:{},
			modelValue: {},
      maxFieldLength: {
        type: Number,
        default: 20
			},
      warningLimit: {
        type: Number,
        default: 10
			},
			disabledFlag: {
				type:Boolean,
				default:false
			},
			errorType:{type:String, default:"length"}
	},
	emits: ['update:modelValue','blured'],
	data() {
		return {
			focused:false
		}
	},
	methods: {
		setFocus() {
			this.focused=true;
		},
		setBlur() {
			this.$emit("blured");
			this.focused=false;
		}
	}
}
</script>
<style scoped>
	.inputErrorMessage {
		position: absolute;
		background-color: v-bind("theme.tooltipBackgrond");
		color:v-bind("theme.formBackgroundHover");
		font-size: 0.5em;
		box-sizing: border-box;
		padding-left: 1em;
		padding-right: 1em;
		padding-top: 0.25em;
		padding-bottom: 0.25em;
		z-index: 4;
	}
	.errorInputField
	{
  border:1px solid  #cc0000!important;
	}
	.editableContentInput:hover {
    box-shadow: none;
    border: 0;
    outline:none;
    outline-width: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  .editableContentInput:focus {

  box-shadow: none;
/*  border-color: #ebebeb;*/
/*border:1px solid #ebebeb;*/
    outline:none;
    outline-width: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  .editableContentInput {
    color:v-bind("theme.titleColor");
		border: 0;
    width:80vw;
  }
  .editableContent:focus {
    outline: 1px solid #aaa;
  }
  .editableContent {
      background-color: v-bind("background");
      padding: 0.15em 0.5em 0.15em 0.25em;
  }
  .editableContent:hover {
    background-color: v-bind("theme.formBackgroundHover");
  }
</style>