<template>
	<div class="interactiveAuthorTag">
	<span>{{this.list.name}}</span>
	<div class="deleteTagIcon" title="Delete" @click="deleteAuthor">
	<svg style="width:12px;height:12px" viewBox="0 0 24 24">
	<path
  fill="#000000"
  d="M 22.413 1.585 C 23.195 2.365 23.196 3.631 22.415 4.413 L 21.852 4.977 L 14.829 12 L 22.414 19.586 C 23.195 20.367 23.195 21.633 22.414 22.414 C 21.633 23.195 20.367 23.195 19.586 22.414 L 12 14.828 L 4.414 22.414 C 3.633 23.195 2.367 23.195 1.586 22.414 C 0.805 21.633 0.805 20.367 1.586 19.586 L 9.172 12 L 2.149 4.977 L 1.587 4.415 C 0.805 3.635 0.804 2.369 1.585 1.587 C 2.365 0.805 3.631 0.804 4.413 1.585 L 4.977 2.148 L 12.001 9.172 L 19.023 2.149 L 19.585 1.587 C 20.365 0.805 21.631 0.804 22.413 1.585 Z"
	></path>
	</svg>
	</div>
	</div>

</template>
<script type="text/javascript">
	


export default {
  props: ["list"],
  methods: {
	deleteAuthor() {
      let item = {
        id: this.list.id,
        text: this.list.name
      };
      this.$emit("author-deleted", item);
	}

  }
};

</script>

<style type="text/css">
	
.interactiveAuthorTag {
	background-color: #d8ecf3;
	width: auto;
	display: inline-block;
	padding-left: 1em;
	padding-right: 0.5em;
	margin-right: 1em;
	margin-bottom: 0.5em;
	padding-top: 0.3em;
	padding-bottom: 0.3em;
	border:1px solid #63b5cf;
	border-radius: 0.3em;
}
.interactiveAuthorTag:hover {
	background-color: #add8e6;
}
.deleteTagIcon {
	opacity: 0.4;
	margin-left:0.45em;
  cursor: pointer;
  display: inline;
}
.deleteTagIcon:hover {
	opacity: 1;
}
</style>