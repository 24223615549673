import axios from 'axios';
import api from "./api";
import TokenService from "./token.service";

// const API_URL = 'http://localhost:8080/api/auth/';
class AuthService {
  login({ username, password }) {
    return api
      .post("/auth/signin", {
        username,
        password
      })
      .then((response) => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register({ username, email, password, invitationCode }) {
    return api.post("/auth/signup", {
      username,
      email,
      password,
      invitationCode
    });
  }
}

export default new AuthService();