<template>
	<div id="mapViewContainer">
		<div id="fileUploader">
			<span id="fileUploaderLabel">JSON file: </span><input type="file" id="mapContentFileBtn" @change="onFileSelected" hidden>
			<label for="mapContentFileBtn" id="mapContentFile" :class="{'mapContentFileEmpty':!fileChosen,'mapContentFileChosen':fileChosen}" >{{filename}}</label>
		</div>
		<div>
			<a href="data.json">JSON Example</a>
		</div>
	<svg id="mapView"></svg>
	</div>
</template>
<script>
import * as d3 from "d3";
import {geoMercator, geoPath} from "d3-geo";

export default {
	computed: {
	},
	data() {
		return {
			filename: "Choose File",
			fileChosen: false,
			mapData: {},
			geoJson: {
				"type": "FeatureCollection",
				"features": []
			},
			
		}
	},
	methods: {
		onFileSelected(event) {
			let reader = new FileReader();
			reader.readAsText(event.target.files[0]); 
			this.filename = event.target.files[0].name;
			this.fileChosen = true;
			reader.onload = function() {
				let jsonFileContent = JSON.parse(reader.result);
				this.mapData = jsonFileContent;
				this.updateDataset()
			}.bind(this);
		},
		updateDataset() {
			const transitionLength = 200;
			const width = d3.select('body').node().getBoundingClientRect().width;
			const height = 900;
			const rotated = 90;
			const data = [];
			let countryMax = 0;
			const minPointR = 2, maxPointR = 4;
			let cityMax = 0, cityMin= undefined;
			const pointRadius = (value) => {
				return  (maxPointR-minPointR)/(cityMax-cityMin)*(value-cityMax) + maxPointR;
			}
			const projection = d3.geoMercator();
			const path = d3.geoPath().projection(projection);
			const svg = d3.select("#mapView");
			svg.selectAll("g").remove()
			svg.attr("width", width).attr("height", height);
			const map = svg.append("g").attr("id", "map");
			map.attr("width", width).attr("height", height);
			let citiesLayout = svg.append("g").attr("id", "citiesLayout");

			svg.call(d3.zoom().on('zoom', (event, d) => {
				map.attr('transform', event.transform);
				citiesLayout.attr('transform', event.transform);
			}));
			this.geoJson.features = [];
			for(let countryName in this.mapData) {
				let country = this.mapData[countryName];
				for(let cityName in country.cities) {
					let city = country.cities[cityName];
					let newFeature = { "type": "Feature", "properties": { "country":countryName, "name": city.name, "value": city.value },
						"geometry": { "type": "Point", "coordinates":[city.lon, city.lat], "raduis":10 } };
					if(city.value>cityMax) {
						cityMax = city.value;
					}
					if(cityMin===undefined) {
						cityMin = city.value;
					}
					else if(cityMin!==undefined && cityMin>city.value) {
						cityMin = city.value;
					}
					this.geoJson.features.push(newFeature);
				}
			}

			d3.json("natural-earth-countries-1_110m@public.geojson").then(countries => {
			countries.features.forEach(d=> {
				let countryName = d.properties.admin;
				if(this.mapData[countryName]) {
					Object.assign(d.properties, this.mapData[countryName]);
					if(this.mapData[countryName].value > countryMax) {
						countryMax = this.mapData[countryName].value;
					}
				}
				else {
					d.properties.value = 0;
				}
			})
			const openTooltip = (title, value) => {
				let tooltipContent = `<div class="tooltipTitle"> ${title} </div>
					<div class="tooltipContent">
					<span class="tooltipContentField">Value </span>
					<span class="tooltipContentFieldValue">${value}</span>
					</div>`;
				tooltip
				.style("visibility", "visible");
				tooltip
				.html(tooltipContent)
				.style("left", (event.pageX)+10+"px")
				.style("top", (event.pageY)+10+"px")
				.transition().duration(600).style("opacity", 1)
			}
			const cityMouseOverEvent = (event, d) => {
				d3.select(event.currentTarget).transition().duration(transitionLength)
					.style("fill", "#CE87EB")
					.style("stroke-width", "2");
				let cityName = d.properties.name;
				openTooltip(cityName, d.properties.value);
			}

			const cityMouseOutEvent = (event, d) => {
				d3.select(event.currentTarget).transition().duration(transitionLength).attr("stroke","black").attr("stroke-width",1)
					.style("fill", "#CEEB87")
					.style("stroke-width", "1");

				tooltip.style("opacity", 0).style("visibility", "hidden");
			}
			const mouseOverEvent = (event, d) => {
				d3.select(event.currentTarget).transition().duration(transitionLength).attr("stroke","gold").attr("stroke-width",10);
				let countryName = d.properties.admin;
				openTooltip(countryName, d.properties.value);
			}
			const mouseOutEvent = (event, d) => {
				d3.select(event.currentTarget).transition().duration(transitionLength).attr("stroke","black").attr("stroke-width",1);

				tooltip.style("opacity", 0).style("visibility", "hidden");
			}
			const tooltip = d3.select("#tooltip");
			const colorScale = d3.scaleLinear([0, countryMax], ["#FFFFFF", "#87ceeb "]);
			map.selectAll('path').data(countries.features)
					.enter().append('path')
					.attr('class', 'country')
					.attr('d', path)
					.attr('fill', d => colorScale(d.properties.value))
					.attr('stroke','black')
					.attr("stroke-width", 1)
					.attr("vector-effect", "non-scaling-stroke")
					.on('mouseover', (event,d) => {
						mouseOverEvent(event,d);
					})
					.on('mouseout', (event,d) => {
						mouseOutEvent(event,d);
					});
			citiesLayout.selectAll('circle').data(this.geoJson.features).enter()
					.append('circle')
					.attr("class","cityNode")
					.attr("r", d=>pointRadius(d.properties.value))
					.attr("cx", d=> projection(d.geometry.coordinates)[0])
					.attr("cy", d=>projection(d.geometry.coordinates)[1])
					.style("fill", "#CEEB87")
					.style("stroke", "black")
					.style("stroke-width", "1")
					.on('mouseover', (event,d) => {
						cityMouseOverEvent(event,d);
					})
					.on('mouseout', (event,d) => {
						cityMouseOutEvent(event,d);
					});
			});

			
		}
	},
	mounted() {
	const tooltip = d3.select("#mapViewContainer").append("div").attr("id", "tooltip");
  },
}
</script>
<style>
	#fileUploader {
		margin: 1em;
	}
	#fileUploaderLabel {
		color:#777;
		font-size: 1.5em;
	}
	#mapContentFile {
		cursor: pointer;
		padding:0.45em;
		border-radius: 0.25em;
		border-width:0.25em;
		border-style: solid;
		transition: background-color 0.4s;
		box-shadow: 1px 0px 1px 1px rgba(192, 192, 192, 0.25);
	}
	.mapContentFileChosen {
		background:#197499;
		border-color:#197499;
	}
	.mapContentFileChosen:hover {
		background:#197499;
	}
	.mapContentFileEmpty {
		border-color:#B1DAE7;
		background:#B1DAE7;
	}
	.mapContentFileEmpty:hover {
		background:#FFFFFF;
	}
	.tooltipContent {
		padding:1em;
	}
	.tooltipTitle {
		background: #197499;
		top:0;
		font-family: "Helvetica";
		padding-top:0.25em;
		padding-bottom:0.25em;
		text-align: center;
		color: white;
		font-size: 1.5em;
		font-weight: light;
	}
	.tooltipContentField {
		font-weight: bolder;
		color:#197499;
	}
	.tooltipContentFieldValue {

	}
	#tooltip {
		top:0;
		opacity: 0;
		border:1px solid #197499;
		font-family: "Helvetica";
		position: absolute;
		visibility: hidden;
		min-width:200px;
		background: white;
		width:10%;
		min-height:50px;
/*		height:20%;*/
	}
	#mapView {
		background: #f5f5f5;
	}
</style>