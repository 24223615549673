<template>
	<footer>
	<div class="footerItem">
		<p>PaperTools 2023</p>
		<ul>
		<li><a href="/">About</a></li>
		<li><a href="/help">Help</a></li>
		<li><a href="/support">Support</a></li>
		<li><a href="/terms">Privacy and Terms</a></li>
	</ul>
	</div>
	</footer>
</template>
<script>
// import MainAppBoard from "@/components/MainAppBoard";
export default {

  computed: {
    theme() {
      return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
    },
}
}
</script>
<style>
	.footerItem {
		text-align: center;
		position: static;
		height: 10em;
		bottom: 0;
		background-color: v-bind("theme.mainColor");
		color: v-bind("theme.background");
		margin-left:-0.75rem;
		margin-right:-0.75rem;
		padding: 1rem;
	}
.footerItem ul li {
	display: inline;
	margin-left: 1em;
	border-left:1px solid;
	padding-left: 1em;
}
.footerItem ul li:first-child {
	border-left:0;
}
.footerItem ul li a {
	color: v-bind("theme.background");
	text-decoration: none;
}
.footerItem ul li a:hover {
	color: v-bind("theme.formBackgroundHover");
}
</style>