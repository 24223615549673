<template>
  <nav class="navbar navbar-light bg-faded">
    <a class="navbar-brand" :href="homeButtonPath">
<div class="d-none d-sm-inline-block" style="margin-right:0.25em;">P<span id="aLetterHeader">a</span>
perTools</div>
<svg id="headerAppIcon" :icon-state="this.$store.state.globalSettings.iconState" width="100%" height="100%" viewBox="0 0 500 500" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"  style="width:1em;fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1;"> <path d="M475.241,408.607L250,25.698L250,474.302L296.925,408.6L475.241,408.607ZM24.76,408.6L250,25.698L250,474.302L203.075,408.6L24.76,408.6ZM250,25.698L203.075,408.6M296.925,408.6L250,25.698" style="fill:none;fill-rule:nonzero;;stroke-width:48px;"/></svg>
<div id="headerAppIconStatus" v-show="showIconStatusText">{{iconStatusText}}</div>
    </a>

    <div>
      <span class="toolbarItem toolIcon" title="User" :class="{ toolIconSelected:profileMenuActive }" @click="openCloseUserMenu"><i class="fa-solid fa-user"></i></span>
    </div>
    <div class="userActionsMenu" v-if="profileMenuActive" :style="{'display:block':profileMenuActive}">
      <div v-if="{profileMenuActive}">
      <div class="userActionsMenuItemImg" v-if="userExpirationToken"><span class="userActionsMenuItemImgFrame" @click="profile"><i class="fa-solid fa-user fa-2x"></i></span></div>
      <div class="userNameLabelText" @click="profile" v-if="userExpirationToken">{{currentUser.username}} 
          <UserStatusFlag :status="currentUser.status"></UserStatusFlag></div>
      </div>
      <div class="userActionsMenuItem" v-if="!userExpirationToken" @click="login"><span class="userActionsMenuItemIcon"><i class="fa-solid fa-right-to-bracket"></i></span> Login</div>
      <div class="userActionsMenuItem" v-if="!userExpirationToken" @click="register"><span class="userActionsMenuItemIcon"><i class="fa-solid fa-user-plus"></i></span> Sign Up</div>
      <div class="userActionsMenuItem" v-if="userExpirationToken" @click="logout"><span class="userActionsMenuItemIcon"><i class="fa-solid fa-right-from-bracket"></i></span> Logout</div>
    </div>
  </nav>
</template>


<script>
import UserStatusFlag from '@/components/mainView/UserStatusFlag';
export default {
  computed: {
    profileMenuActive() {
      if(this.$store.state.globalSettings.userMenuOpen) {
        return true
      }
      return false
    },
    homeButtonPath() {
      if(this.userExpirationToken) {
        return '/profile';
      }
      return '/';
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    userExpirationToken() {
      if(this.currentUser) {
        return Date.parse(this.currentUser.expirationToken) > Date.now(); 
      }
      return false;
      },
    theme() {
      return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
    },
    iconStatusText() {
      if(this.$store.state.globalSettings.iconState=="ERROR") {
        return "Something was wrong and board was not saved. Please refresh"
      }
      if(this.$store.state.globalSettings.iconState=="SUBMITTING") {
        return "Saving..."
      }
      return ""
    },
    showIconStatusText() {
      if(this.$store.state.globalSettings.iconState!="DEFAULT") {
        return true;
      }
      return false;
    }
  },
  components: {
    UserStatusFlag
  },
  data() {
    return {
    };
  },
  methods: {
    openCloseUserMenu() {
      this.$store.state.globalSettings.userMenuOpen=true;
      this.$store.state.globalSettings.showAppCurtainFunction="profile";
      this.$store.state.globalSettings.showAppCurtain=true;
    },
    profile() {
      this.$store.state.globalSettings.userMenuOpen=false;
    this.$store.state.globalSettings.showAppCurtain=false;
      this.$router.push('/profile');
    },
    login() {
      this.$store.state.globalSettings.userMenuOpen=false;
    this.$store.state.globalSettings.showAppCurtain=false;
      this.$router.push('/login');
    },
    logout() {
      this.$store.state.globalSettings.userMenuOpen=false;
    this.$store.state.globalSettings.showAppCurtain=false;
      this.$router.push('/logout');
    },
    register() {
      this.$store.state.globalSettings.userMenuOpen=false;
    this.$store.state.globalSettings.showAppCurtain=false;
      this.$router.push('/register');
    }
  }
};
</script>
<style scoped>

#aLetterHeader {
  padding-left:0.05em;
  visibility: hidden;
}

.userNameLabelText:hover {
  color:#777;
  cursor: pointer;
}

.userNameLabelText {
  text-align: center;
  margin-top: 0.5em;
  font-weight: lighter;
}
.userActionsMenuItemImgFrame:hover .userNameLabelText{
  color:#777;
  background-color: v-bind("theme.formBackgroundHover");
}
.userActionsMenuItemImgFrame:hover {
  cursor: pointer;
  border-color: #aaa;
  box-shadow: 1px 1px 1px 0px rgba(128, 128, 128, 0.75);
}
.userActionsMenuItemImgFrame {
  width: 2em;
  padding: 0.5em;
  padding-top: 1em;
  border-radius: 2em;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px 0px rgba(158, 158, 158, 0.25);


}
.userActionsMenuItemImg {
    text-align: center;
    padding-top:1em;
    padding-bottom: 0.25em;
}
.userActionsMenuItemIcon {
  padding-right: 0.5em;
}
.userActionsMenuItem:hover {
  background-color: #ebebeb;
  cursor: pointer;
}
.userActionsMenuItem {
  border-bottom: 2px solid rgba(0,0,0,0.175);
  padding-left: 1em;
  padding-right: 4em;
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  font-weight: lighter;
  transition: background-color 0.1s;
}
.userActionsMenu {
  background-color: #ffffff;
  padding-top: 0em;
  padding-bottom: 0em;
  box-shadow: 1px 1px 1px 0px rgba(158, 158, 158, 0.25);
  border: 1px solid #ebebeb;
  position: absolute;
  top:3em;
  right: 0.5em;
  z-index: 3;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
  .navbar {
    background-color: #B1DAE7;
    margin-left:-0.75rem;
    margin-right:-0.75rem;
  height: 3em;
  }
  .toolbarItem {
    margin-top:-0.25em;
    margin-right: 0.5em;
  }
.selectedButton {
  background-color: #18414e;
}
.navbar-toggler {
  margin-top: 7px;
}
.navbar-brand {
  font-size: 2em;
  padding-left:1em;
  font-weight: 600;
  color: #2196f3 !important;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 1em;
  max-height: 55px;
}
.navbar-brand > label {
  font-size: 14px;
  line-height: 1em;
  vertical-align: middle;
  color: #000;
}
#headerAppIcon {
  position: absolute;
  margin-left:-5.1em;
  top:0.1em;
}
#headerAppIcon path {
  transform-origin: center;
  transition: 0.5s;
}
#headerAppIcon[icon-state="SUBMITTING"] path {
  animation: MoveUpDown 1s linear infinite;
  d: path("M470.163,232.785L54.621,43.519L354.372,428.088L356.428,292.728L470.163,232.785ZM142.551,406.774L54.621,43.519L354.372,428.088L280.163,332.88L142.551,406.774ZM54.621,43.519L280.163,332.88M356.428,292.728L54.621,43.519");
  stroke:rgb(33,150,243)
}
#headerAppIcon[icon-state="DEFAULT"] path {
  d: path("M475.241,408.607L250,25.698L250,474.302L296.925,408.6L475.241,408.607ZM24.76,408.6L250,25.698L250,474.302L203.075,408.6L24.76,408.6ZM250,25.698L203.075,408.6M296.925,408.6L250,25.698");
  stroke:rgb(33,150,243)
}
#headerAppIcon[icon-state="ERROR"] path {
  d: path("M475.241,408.607L250,25.698L250,474.302L296.925,408.6L475.241,408.607ZM24.76,408.6L250,25.698L250,474.302L203.075,408.6L24.76,408.6ZM250,25.698L203.075,408.6M296.925,408.6L250,25.698");
  stroke:rgb(249,57,57);
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(40px) rotate(2deg);
  }
}
#headerAppIconStatus {
  position: absolute;
  font-size: 0.4em;
  font-weight: lighter;
  padding:0 0.5em 0 0.5em;
  border-radius: 0.5em;
  color:v-bind("theme.listItemHover");
  background-color: v-bind("theme.tooltipBackgrond");
  z-index: 5;
}
</style>
