<template>
  <div id="metadataeditor">
    <div id="titleHeader">
    <button class="btn btn-outline btn-sm closeButton" @click.prevent="cancelPaper" title="Close"><i class="fa-solid fa-xmark fa-2x" ></i></button>
    <button class="btn btn-outline-secondary btn-sm deleteButton" @click.prevent="deletePaper" title="Delete Paper"><i class="fa-solid fa-bucket fa-1x" ></i></button>
    <div v-if="currentPaper.authors.length>1"><div class="authorName">{{ currentPaper.authors[0].name }} et al </div><div class="paperTitle">{{ currentPaper.title }}</div></div>
    <div v-if="currentPaper.authors.length==1"><div class="authorName">{{ currentPaper.authors[0].name }}</div> <div class="paperTitle">{{ currentPaper.title }}</div></div>
    <div v-if="currentPaper.authors.length==0"><div class="paperTitle">{{ currentPaper.title }}</div></div>
    </div>
<div class="mb-3" :style="{background: theme.background}">
<div class="fileUploadingForm">
    <UploadedFileManager :key="updatedKey" :attachedFiles="currentPaper.attachedFiles" :apiUrl="fileUploadUrl" :deleteApiUrl="fileDeleteUrl" :downloadApiUrl="fileOpenUrl" @fileUploaded="fileUploaded" @fileDeleted="fileDeleted"></UploadedFileManager>
</div>
<div class="paperLink form-floating" v-show="currentPaper.link.length>0">
  Link: <a :href="this.currentPaper.link" target="_blank">{{currentPaper.link}}</a> <span title="Remove link" class="removeFieldButton" @click="removeLink"><i class="fa-solid fa-xmark fa-1x" ></i></span>
</div>
  <div class="form-floating">DOI <HintTooltip :hint="{text:'If DOI specified and correct paperTools will make an attempt to autofill the following fields. Only Pro users.'}"></HintTooltip>
  </div>
<TextAreaNotification v-model="currentPaper.doi" :errorClass="!checkDOIStyle" @keypress.enter.prevent="processDOI" @blurevent="processDOI" placeholder="DOI" :maxLength="96" blurTrigger/>
<TextAreaNotification @keypress.enter.prevent="" v-model="currentPaper.title" placeholder="Title" :maxLength="200" multiLayer/>

<TextAreaNotification v-model="currentPaper.comment" placeholder="Comment" :maxLength="5000" multiLayer/>
<div class="form-floating">Authors <HintTooltip :hint="{text:'When the author name is entered please click on free area near the field or press Enter. You can also change order by dragging authors in the list'}"/>
      <draggable
        :list="this.currentPaper.authors"
        group="authorList"
        @start="drag=true"
        @end="drag=false"
      >
      <InteractiveAuthorTag 
        v-for="(listItem, index) in currentPaper.authors"
        :key="index"
        :list="listItem"
        @author-deleted="authorDeleted"
        :style="{color: theme.invertedTitleColor}"
        />
      </draggable>
</div>
<div class="input-group mb-3">
  <!-- <input type="text" class="form-control" placeholder="New author" id="floatingTextareaComment" v-model="item.newAuthor" @keypress.enter="addNewAuthor" @blur="addNewAuthor"/> -->
  <TextAreaNotification v-model="item.newAuthor" :errorMsg="this.item.newAuthorError" placeholder="New author" :maxLength="50" @keypress.enter.prevent="addNewAuthor" @focusevent="clearNewAuthorError" @blurevent="addNewAuthor" blurTrigger></TextAreaNotification>

</div>
</div>
<div class="form-floating">
  Special status <HintTooltip :hint="{text:'When selected the corresponding status will be visible on the board.'}"></HintTooltip>
<div class="funkyradio">
  <div class="form-check form-check-inline funkyradio-success" id="readCheckBoxBlock">
    <input class="form-check-input" type="checkbox" id="readCheckBox" v-model="currentPaper.read">
    <label class="form-check-label " for="readCheckBox"><i class="fa-solid fa-circle-check fa-1x"></i> Read</label>
  </div>
  <div class="form-check form-check-inline funkyradio-warning">
    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" v-model="currentPaper.important">
    <label class="form-check-label" for="inlineCheckbox2"><i class="fa-solid fa-triangle-exclamation fa-1x"></i> Important</label>
  </div>
  <div class="form-check form-check-inline funkyradio-danger">
    <input class="form-check-input" type="checkbox" id="inlineCheckbox3" v-model="currentPaper.favourite">
    <label class="form-check-label" for="inlineCheckbox3"><i class="fa-solid fa-heart fa-1x"></i> Favourite</label>
  </div>
</div>
</div>

<div class="form-floating">
  Notification <i class="fa-regular fa-bell"></i>
  
  <Datepicker v-model="currentPaper.notificationDate" :enable-time-picker="false" auto-apply :close-on-auto-apply="true"></Datepicker>
</div>
<TextAreaNotification v-model="currentPaper.review" placeholder="Review" :maxLength="5000" multiLayer></TextAreaNotification>
<TextAreaNotification @keypress.enter.prevent="" v-model="currentPaper.link" placeholder="Link" :maxLength="500"></TextAreaNotification>
<TextAreaNotification @keypress.enter.prevent="" v-model="currentPaper.publisher" placeholder="Publisher" :maxLength="150"></TextAreaNotification>
<TextAreaNotification @keypress.enter.prevent="" v-model="currentPaper.publishedCity" placeholder="Publisher City" :maxLength="150"></TextAreaNotification>
<TextAreaNotification @keypress.enter.prevent="" v-model="currentPaper.publishedCountry" placeholder="Publisher Country" :maxLength="50"></TextAreaNotification>
<TextAreaNotification @keypress.enter.prevent="" v-model="currentPaper.publishedYear" placeholder="Publisher Year" :maxLength="4"></TextAreaNotification>


<div class="form-floating">Rating <HintTooltip :hint="{text:'If you want to reset rating please click on a blank area around the stars'}"></HintTooltip>
<div class="ratingZone" @click.self="resetRating" title="Reset rating">
    <StarRating class="ratingZoneStars" :bgColorDefault="theme.ratingBgColorDefault" v-model="currentPaper.rating" :maxStars=5 :key="updatedKey"></StarRating>
</div>
</div>
<div class="form-floating">Color <HintTooltip :hint="{text:'Color of the paper entry on the board'}"></HintTooltip>
<ColorPicker v-model="currentPaper.color">
</ColorPicker>

      <!-- <RawView class="col-3" :value="currentPaper" title="List 1" /> -->
</div>  
<div class="form-floating">Keywords <HintTooltip :hint="{text:'Please fill the keywords that you plan to use for search of papers. When the word (or phrase) is entered please click on free area near the field or press Enter'}"></HintTooltip>
<div id="tagsList">

      <draggable
        :list="this.currentPaper.tags"
        group="TagsList"
        @start="drag=true"
        @end="drag=false"
      >
      <InteractiveTag 
        v-for="(listItem, index) in currentPaper.tags"
        :key="index"
        :list="listItem"
        @tag-deleted="tagDeleted"
        :style="{color: theme.invertedTitleColor}">
        </InteractiveTag>
      </draggable>
        
  <TextAreaNotification v-model="item.newTag"  :errorMsg="this.item.newTagError" placeholder="New tag" :maxLength="50" @keypress.enter.prevent="addNewTag" @focusevent="clearNewTagError" @blurevent="addNewTag" blurTrigger></TextAreaNotification>
</div>

</div>


        <div class="form-group-paper text-center">
          <PaperToolsButton
            buttonTitle="Save"
            buttonIcon="fa-plus"
            @click.prevent="saveEditedPaper"
          ></PaperToolsButton>
          <PaperToolsButton
            buttonTitle="Cancel"
            buttonIcon="fa-xmark"
            buttonStyle="cancel"
            @click.prevent="cancelPaper"
          >
          </PaperToolsButton>
<!--           <button class="btn btn-outline-primary btn-sm mr-2" @click.prevent="saveEditedPaper" style="margin-right: 2em;">Save</button>
          <button class="btn btn-outline-secondary btn-sm" @click.prevent="cancelPaper">Cancel</button> -->
        </div>


  </div>
</template>
<script>
import StarRating from '@/components/StarRating'
import InteractiveAuthorTag from "@/components/InteractiveAuthorTag";
import InteractiveTag from "@/components/InteractiveTag";
import HintTooltip from "@/components/HintTooltip";
import ColorPicker from "@/components/ColorPicker";
import UploadedFileManager from "@/components/UploadedFileManager";
import TextAreaNotification from "@/components/TextAreaNotification";
// import RawView from "@/components/rawview"
import { VueDraggableNext } from 'vue-draggable-next';
import PaperToolsButton from '@/components/mainView/PaperToolsButton';
import authHeader from '../services/auth-header';
import axios from 'axios';
const { v4: uuidv4 } = require('uuid');

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const props = {
  title: {
    required: true,
    type: String
  },
  // value: {
  //   required: true
  // }
};
export default {
  components: {
    StarRating,
    InteractiveAuthorTag,
    InteractiveTag,
    UploadedFileManager,
    // RawView,
    HintTooltip,
    draggable: VueDraggableNext,
    ColorPicker,
    PaperToolsButton,
    Datepicker,
    TextAreaNotification
  },
  name: "raw-displayer",
  props:["currentPaper","apiUrl","boardId"],
  data(){
    return {
        item:
        {
          newAuthor:"",
          authorName:"",
          newAuthorError:"",
          newTag:"",
          newTagError:"",
        },
        DOIerror: ""
      }
  },
  computed: {
    theme() {
      return this.$store.state.globalSettings.themes[this.$store.state.globalSettings.currentTheme]
    },
    titleHeaderC() {
      if(this.currentPaper.color.toLowerCase()=="#ffffff") {
        return this.theme.mainColor;
      }
      return this.currentPaper.color;
    },
    fileDeleteUrl() {
      return `${this.apiUrl}/deleteFile?boardId=${this.boardId}`
    },
    fileOpenUrl() {
      return `${this.apiUrl}/getFile?boardId=${this.boardId}`
    },
    fileUploadUrl() {
      return `${this.apiUrl}/uploadFile?boardId=${this.boardId}`
    },
    updatedKey() {
      return `${this.currentPaper.id}${this.currentPaper.rating}`
    },
    checkDOIStyleMessage() {
      if(this.currentPaper.doi.length > 10) {
            if((!/^10\..+\/.+$/.test(this.currentPaper.doi))) {
                return"Incorrect format";
              }
            }
      return ""
    },
    checkDOIStyle() {
        if(this.currentPaper.doi.length > 10) {
            if((!/^10\..+\/.+$/.test(this.currentPaper.doi))) {
                return false
            }
        }
        return true
    }
  },
  methods: {
    processDOI() {
      if(this.$store.state.globalSettings.proFuncStatus.includes(authHeader().status)) {
        let apiBackend = this.$store.state.globalSettings.backendUrl+this.$store.state.globalSettings.apis.doiAPI;
        if(this.currentPaper.doi.length > 10 && this.checkDOIStyle) {
          if(this.currentPaper.title=="New Paper" || this.currentPaper.authors.length==0) {
        axios.post(apiBackend, 
            {'doi':this.currentPaper.doi}, 
            {headers:authHeader()})
        .then(res=> {
            if(res.status==200) {
                let result = res.data.body;
                let authors = result.author;

                this.currentPaper.title=result.title;
                this.currentPaper.link=result.URL;
                this.currentPaper.isbn=result.ISSN;
                this.currentPaper.publisher=result.publisher;

                for(let authorI = 0; authorI<authors.length; authorI++) {
                  let a = authors[authorI];
                    this.addNewAuthorP(`${a.given} ${a.family}`);
                }
                if(result.published["date-parts"]) {
                  this.currentPaper.publishedYear=result.published["date-parts"][0][0];
                }
            }
        })
        .catch(err=>{})
          }
          else {
            if(this.currentPaper.title.length>20) {
              // prompt if replace
            }
          }
        }
      }
    },
    removeLink() {
      this.currentPaper.link = "";
    },
    resetRating() {
      this.currentPaper.rating = 0;
    },
    fileUploaded(newFile) {
      if(newFile.metaDataExtracted) {
        this.currentPaper.title= newFile.metaDataTitle;
        this.currentPaper.doi = newFile.metaDataDoi;
        this.processDOI();
      }
      this.currentPaper.attachedFiles.push(newFile);
      this.$emit("paper-saved", this.currentPaper, true);
    },
    fileDeleted(fileParams) {
      this.currentPaper.attachedFiles = []
      this.$emit("paper-saved", this.currentPaper, true);
    },
    deletePaper: function() {
      this.$emit("paper-deleted");
    },
    cancelPaper: function() {
      this.$emit("paper-cancelled");
    },
    saveEditedPaper: function() {
      this.$emit("paper-saved", this.currentPaper);
    },
    clearNewAuthorForm: function() {
      this.item.newAuthor = ""
    },
    clearNewAuthorError: function() {
      this.item.newAuthorError = "";
    },
    clearNewTagForm: function() {
      this.item.newTag = ""
    },
    clearNewTagError: function() {
      this.item.newTagError = "";
    },
    tagDeleted: function(item){
      let itid = this.currentPaper.tags.findIndex(t => t.id == item.id);
      this.currentPaper.tags.splice(itid,1)
    },
    addNewAuthorP(authorName) {
      if(authorName.length>0) {
        if(this.currentPaper.authors.filter(e=>e.name.toLowerCase()==authorName.toLowerCase()).length>0) {
          return;
        }
        let max_id = uuidv4();
        let new_item = {id:max_id, name:authorName};
        this.currentPaper.authors.push(new_item);
      }
    },
    addNewTag : function() {
      const minTagNameLength = 1;
      let newTag = this.item.newTag.toString().trim();
      if(newTag.length>0) {
        if(newTag.length<minTagNameLength) {
          this.item.newTagError = "Too short tag name";
          return;
        }
        if(this.currentPaper.tags.filter(e=>e.name.toLowerCase()==newTag.toLowerCase()).length>0) {
          this.item.newTagError = "Tag already exists";
          return;
        }
        if(newTag.length>=minTagNameLength) {
          let new_item = {id:uuidv4(), name:newTag};
          this.currentPaper.tags.push(new_item);
          this.clearNewTagForm();
        }
      }
    },
    addNewAuthor : function() {
      const minAuthorNameLength = 2;
      let newAuthoName = this.item.newAuthor.toString().trim();
      if(newAuthoName.length>0) {
        if(newAuthoName.length<minAuthorNameLength) {
          this.item.newAuthorError = "Too short name";
          return;
        }
        if(this.currentPaper.authors.filter(e=>e.name.toLowerCase()==newAuthoName.toLowerCase()).length>0) {
          this.item.newAuthorError = "Author already exists";
          return;
        }
        if(newAuthoName.length>=minAuthorNameLength) {
          let new_item = {id:uuidv4(), name:newAuthoName};
          this.currentPaper.authors.push(new_item);
          this.clearNewAuthorForm();
        }
      }


    },
    authorDeleted: function(item){
      let itid = this.currentPaper.authors.findIndex(t => t.id == item.id);
      this.currentPaper.authors.splice(itid,1)
    }
  }
};
</script>
<style scoped>
  .errorInField {
    border-color: #cc0000;
  }
  .removeFieldButton {
    opacity: 0.5;
  }
  .removeFieldButton:hover {
    opacity: 1;
  }
  .ratingZone {
    position: relative;
    padding-top: 2em;
    padding-bottom: 2em;
    z-index: 4;
  }
  .ratingZoneStars {
    z-index: 5;
    position: relative;
  }
  .authorName {
    font-size: 1em;
    color:v-bind("theme.descColor");
  }
  .paperTitle {
    font-size: 1.5em;
    color:v-bind("theme.titleColor");
    font-weight: lighter;
  }
  .paperLink {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .form-check:first-of-type {
    padding-left:0em;
  }
  .form-check {
    padding-left:0.5em;
  }
  .form-check-inline {
    margin-right: 0.1em;
  }
  .closeButton {

  position: absolute;
  right: 0.5em;
  top:0.5em;
  }
.deleteButton {
  position: absolute;
  right: 0.5em;
  top:3.5em;
}
#titleHeader {
  width:auto;
  position: relative;
  background:v-bind("titleHeaderC");
  padding-top:0.5em;
  padding-bottom:0.25em;
  padding-left:2em;
  padding-right:3em;
/*  margin-left: 2em;*/
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  color:#ffffff;
  min-height: 6em;
  margin-left:-1em;
}
#metadataeditor {
  padding-left: 1em;
  background-color: v-bind("theme.background");
  color:v-bind("theme.titleColor  ");
  overflow-y: scroll;
  height: 100vh;
  width:27vw;
  min-width: 400px;
  padding-bottom: 3em;
  position: flex;
  flex-direction: column;
    left:auto;
    right: 0;
}
pre {
  text-align: start;
}
textarea.form-control {
  background-color: v-bind("theme.formBackground");
  color:v-bind("theme.titleColor");
  resize: none;
}

.funkyradio div {
  clear: both;
  overflow: hidden;
}

.funkyradio label {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #D1D3D4;
  font-weight: normal;
  padding-right: 0.75em;
}

.funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="checkbox"]:empty ~ label {
  position: relative;
  line-height: 2.5em;
  text-indent: 3.25em;
  margin-top: 0.5em;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.funkyradio input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: '';
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px;
}

.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: '\2714';
  text-indent: .6em;
  color: #C2C2C2;
}

.funkyradio input[type="checkbox"]:checked ~ label {
  color: #777;
}

.funkyradio input[type="checkbox"]:checked ~ label:before {
  content: '\2714';
  text-indent: .6em;
  color: #333;
  background-color: #ccc;
}

.funkyradio input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}
.funkyradio-success input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #5cb85c;
}
.funkyradio-warning input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #f0ad4e;
}

.funkyradio-danger input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #d9534f;
}
.funkyradio-info input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #5bc0de;
}
.funkyradio-warning input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #f0ad4e;
}
.fileUploadingForm {
  padding-left: 0em;
  padding-right: 0.5em;
  padding-bottom: 1em;
  padding-top: 1em;
}
</style>