<template>
	<div class="colorSelectorItem" @click="selectColor" :title="itemColor.name" :class="{ colorSelectorSelected:selectedItem }">
	</div>
</template>

<script type="text/javascript">
	
export default {
  props: ["itemColor", "currentColor"],
  computed: {
		selectedItem() {
		if(this.currentColor.toUpperCase()==this.itemColor.code.toUpperCase()) {
			return "colorSelectorSelected";
		}
		return "";
		}
	},
  methods: {
	selectColor() {
		this.$emit("color-selected", this.itemColor);
	}
  }
 }

</script>
<style type="text/css">
	
	.colorSelectorItem {
	height: 1.5em;
	width: 1.5em;
	border-radius: 0.25em;
	border: 1px solid #e8ece0;
	background-color: v-bind('itemColor.code');
	display: inline-block;
	margin: 0.5em;
	}
	.colorSelectorItem:active {
		border-color: #2f4f4f;
	}
	.colorSelectorSelected {
		border-color: #2f4f4f;
	}
</style>