<template>
  <div class="card">
    <div class="card-body groupBody">
      <!-- Display Item Markup Start -->
      <div v-show="!isEditing">
        <span class="groupNameTitle text-style disable-select">{{item.text}}</span>
        <div class="edit-icon" @click.prevent="handleEdit" title="Edit" v-show="!this.item.paperbin">
          <svg viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M20.71,4.04C21.1,3.65 21.1,3 20.71,2.63L18.37,0.29C18,-0.1 17.35,-0.1 16.96,0.29L15,2.25L18.75,6M17.75,7L14,3.25L4,13.25V17H7.75L17.75,7Z"
            ></path>
          </svg>
        </div>

        <div class="delete-icon" title="Delete" @click="deleteItem" v-show="!this.item.paperbin">
          <svg viewBox="0 0 24 24">
            <path
              fill="#000000"
              d="M 22.413 1.585 C 23.195 2.365 23.196 3.631 22.415 4.413 L 21.852 4.977 L 14.829 12 L 22.414 19.586 C 23.195 20.367 23.195 21.633 22.414 22.414 C 21.633 23.195 20.367 23.195 19.586 22.414 L 12 14.828 L 4.414 22.414 C 3.633 23.195 2.367 23.195 1.586 22.414 C 0.805 21.633 0.805 20.367 1.586 19.586 L 9.172 12 L 2.149 4.977 L 1.587 4.415 C 0.805 3.635 0.804 2.369 1.585 1.587 C 2.365 0.805 3.631 0.804 4.413 1.585 L 4.977 2.148 L 12.001 9.172 L 19.023 2.149 L 19.585 1.587 C 20.365 0.805 21.631 0.804 22.413 1.585 Z"
            ></path>
          </svg>
        </div>
      <draggable
        :list="this.item.papers"
        group="paperList"
        v-bind="dragOptions"
        :disabled="paperEditingState"
        @start="dragPaper=true"
        @end="dragPaper=false,paperMoved()"
      >

        <transition-group type="transition" :name="!dragPaper ? 'flip-list' : null">
        <PaperEdit
          v-for="(paper) in this.item.papers"
          :list="paper"
          :key="paper.id"
          :searchQuery="searchQuery"
          @paper-editing="paperEditing"
        ></PaperEdit>
      </transition-group>
      </draggable>
        <!-- class="fixed-card" -->
      <PaperNew
        v-show="canAddNewPaper"
        :list="defaultPaperItem"
        :disabledActions="disabledFlag"
        @paper-created="paperCreated"
      ></PaperNew>
      </div>
      <!-- Display Item Markup End -->
      <!-- Form Markup Start -->
      <div v-show="isEditing" class="form">
        <div class="form-group">
          <!-- <textarea rows="2" class="form-control textFormWithColor" v-model="paperGroup.text"></textarea> -->
        <TextAreaNotification @keypress.enter.prevent="" class="groupTitleEditField" style="z-index:1;position: relative;" v-model="paperGroup.text" placeholder="Category title" :maxLength="this.$store.state.globalSettings.maxGroupNameLength"></TextAreaNotification>
        </div>

        <span class="colorPickerGroupLabel">Color:</span>
        <div class="colorPickerGroupForm">
        <ColorPicker v-model="item.color">
        </ColorPicker>
        </div>
        <div class="form-group text-center formSaveButtons">
          <button class="btn btn-outline-primary btn-sm mr-2" @click.prevent="save">Save</button>
          <button class="btn btn-outline-secondary btn-sm" @click.prevent="cancel">Cancel</button>
        </div>
      </div>
      <!-- Form Markup End -->
    </div>
  </div>
</template>

<script>
import PaperNew from "@/components/PaperNew";
import PaperEdit from "@/components/PaperEdit";
import TextAreaNotification from "@/components/TextAreaNotification";
import { VueDraggableNext } from 'vue-draggable-next';
import ColorPicker from "@/components/ColorPicker";
export default {
  components: {
    PaperNew,
    PaperEdit,
    ColorPicker,
    draggable: VueDraggableNext,
    TextAreaNotification,
  },
  props: ["item","defaultpapertext","paperEditingState","searchQuery","disabledFlag","canAddPaper"],
  data() {
    return {
      dragPaper:false,
      paperGroup: {
        id: "",
        text: "",
        color: "",
        papers:[]
      },
      isEditing: false,
      isEditingPaper: {state:false}
    };
  },
  computed: {
    canAddNewPaper() {
      if(!this.item.paperbin && this.canAddPaper) {
        return true
      }
      return false
    },
    shouldUseDragHandle() {
      return this.isDesktop ? false : true;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "paperList",
        disabled: this.disabledFlag,
        ghostClass: "ghost"
      };
    },
    defaultPaperItem() {
      return { id: 0, title: this.defaultpapertext };
    },

    contentColor(){
      let prc = 10;
      let hex = this.item.color
      hex = hex.replace(/^\s*#|\s*$/g, '');
      if(hex.length == 3){
          hex = hex.replace(/(.)/g, '$1$1');
      }
      const mult = 0.97;
      var r = parseInt(hex.substr(0, 2), 16),
          g = parseInt(hex.substr(2, 2), 16),
          b = parseInt(hex.substr(4, 2), 16);
        return `rgb(${Math.round(r*mult)},${Math.round(g*mult)},${Math.round(b*mult)})`;
      },
    headerColor() {
      var c = this.item.color.substring(1);      // strip #
      var rgb = parseInt(c, 16);   // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff;  // extract red
      var g = (rgb >>  8) & 0xff;  // extract green
      var b = (rgb >>  0) & 0xff;  // extract blue
      var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
      if (luma < 150) {
          return "#ffffff";
      }
      else {
          return "#000000";
      }
    },
  },
  methods: {
    uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
    },
    handleEdit() {
      if(!this.disabledFlag) {
      this.paperGroup.id = this.item.id;
      this.paperGroup.text = this.item.text;
      this.isEditing = true;
      this.$emit("item-editing");
      }
    },
    clearForm() {
      this.paperGroup.id = "";
      this.paperGroup.text = "";
      this.paperGroup.color = "#ffffff";
    },
    save() {
      let item = {
        id: this.paperGroup.id,
        text: this.paperGroup.text,
        color: this.item.color
      };
      this.clearForm();
      this.isEditing = false;
      this.$emit("item-edited", item);
    },
    cancel() {
      this.clearForm();
      this.isEditing = false;
      this.$emit("item-cancelled");
    },
    deleteItem() {
      if(!this.disabledFlag) {
      let item = {
        id: this.item.id,
        text: this.item.text
      };
      this.$emit("item-deleted", item);
      }
    },

    paperEditing(paper) {
      // this.isEditingPaper.state = true;
      paper.isEditingPaper = true;
      this.$emit("paper-editing", paper, this.item.id);
    },
    paperCreated(paper) {
      // let newCategoryId = 0;
      // if(this.item.papers.length>0) {
      //   newCategoryId = Math.max(...this.item.papers.map(o => o.id)) + 1
      // }
      paper.id = this.uuidv4();
      paper.newPaper = true;
      this.item.papers.push(paper);
      this.paperEditing(paper);
      this.$emit("group-updated",{"item":"paper","newItem":paper,"group":this.item.id, "action":"create"});
    },
    paperMoved() {
      this.$emit("group-updated",{"item":"paper","group":this.item.id, "action":"moved"});
    }
  }
};
</script>

<style scoped>
.groupNameTitle {
  font-size: 1.4em;
  font-weight: lighter;
  color:v-bind("headerColor");
}
.groupBody {
  background-color: v-bind('item.color');

    border-radius: 5px;
}
.groupBody:hover {
  cursor: move;
  background-color:v-bind('contentColor')
}
.card {
  min-height: 50px;
  border-bottom: 0.01rem solid rgba(0, 0, 0, 0.9);
  font-size: 13px;
  background-color: rgba(255, 255, 255, 1);
}
.card-body {
  padding: 1rem !important;
}
.card:hover {
  background: rgba(0, 0, 0, 0.02);
}
.card:hover div.edit-icon,.card:hover div.delete-icon {
  display: block;
}

@media (max-width: 400px) { 
  div.edit-icon, div.delete-icon  {
    display: block;
  }
  div.edit-icon svg, div.delete-icon svg {
    width:24px;
    height: 24px;
  }
  .edit-icon {
    right:40px!important;
  }
}
.edit-icon svg, .delete-icon svg {
  height: 15px;
  width: 15px;
}
.edit-icon svg path{
  fill:v-bind("headerColor");
}
.delete-icon svg path{
  fill:v-bind("headerColor");
}
.edit-icon {
  position: absolute;
  top: 5px;
  right: 25px;
  display: none;
  cursor: pointer;
  opacity: 0.5;
}
.edit-icon:hover {
  opacity: 1;
}
.delete-icon {
  position: absolute;
  top: 3px;
  right: 8px;
  display: none;
  cursor: pointer;
  opacity: 0.5;
}
.delete-icon:hover {
  opacity: 1;
}
span.text-style {
  white-space: normal;
  padding-left: 10px;
}



.disable-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.colorPickerGroupForm {
  width:80%;
  left:4em;
  background-color: #ffffff;
  margin-top: -2.5em;
  z-index: 2;
  position: relative;
}
.colorPickerGroupLabel {
/*  font-size: 13px;*/
  color:#888;
  font-weight: lighter;
  float:left;
  padding-right: 2em;padding-left:1em;z-index:2;position: relative; margin-top:-2em;
}
.textFormWithColor {
  z-index:1;position: relative;
}

.flip-list-move {
  transition: transform 0.5s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>

