<template>	
	<div class="statusLabel" v-show="showStatus">
		<span>
			{{status}}
		</span>
	</div>
</template>
<script>
const statuses = {
	'test':'#3ca3c3',
	'pro':'#ff8f66',
	'admin':'#aa0000'
}
export default {
  name: 'UserStatusFlag',
  props:['status'],
  computed: {
	showStatus() {
		if(this.status in statuses) {
			return true;
		}
		return false;
	},
	statusColor() {
		if(this.showStatus) {
			return statuses[this.status]
		}
		return ''
	}
  }
}

</script>

<style>
  .statusLabel {
    margin-left: 0.25em;
    padding: 0.15em;
    color:#ffffff;
    transform: skewX(-10deg);
    vertical-align: super;
    display: inline-block;
    font-weight: bolder;
    font-size: 0.5em;
	background-color: v-bind('statusColor');
  }
  .statusLabel span {
    transform: skewX(0deg);
  }

</style>