import axios from 'axios';
// import authHeader from './auth-header';
import store from '../store';


import api from './api';

const API_URL = `${store.state.globalSettings.backendUrl}/api/test/`;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user');
    // , { headers: authHeader() }
  }

  // getModeratorBoard() {
  //   return axios.get(API_URL + 'mod', { headers: authHeader() });
  // }

  // getAdminBoard() {
  //   return axios.get(API_URL + 'admin', { headers: authHeader() });
  // }
}

export default new UserService();
