import { createRouter, createWebHistory } from 'vue-router'
import MainView from '../components/MainAppBoard';
import Home from '../components/mainView/HomeView';
import Login from '../components/mainView/LoginView';
import Register from '../components/mainView/RegisterView';
import LogOutView from '../components/mainView/LogOutView';
import HelpView from '../components/mainView/HelpView';
import SupportView from '../components/mainView/SupportView';
import TermsView from '../components/mainView/TermsView';
import DemoMapView from '../components/extras/MapView';

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes: [
    {
      path: '/board/:boardId',
      name: 'board',
      component: MainView,
      // props: { paperBinOpened:true, paperBinInit:true }
    },
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/home',
      component: Home
    },
    {
      path: '/login',
      component: Login
    },
    {
      path: '/logout',
      component: LogOutView
    },
    {
      path: '/register',
      component: Register
    },
    {
      path: '/support',
      component: SupportView
    },
    {
      path: '/terms',
      component: TermsView
    },
    {
      path: '/help',
      component: HelpView
    },
    {
      path: '/profile',
      name: 'profile',
      // lazy-loaded
      component: () => import('../components/mainView/ProfileView')
    },
    {
      path: '/test',
      name: 'test',
      component: () => import('../components/TestView')
    },
    {
      path: '/extras/mapdemo',
      name: 'mapdemo',
      component: DemoMapView
    }
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // }
  ]
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home', '/', '/support', '/help', '/terms', '/extras'];
  let authRequired = true;
  if(publicPages.includes(to.path) || to.path.startsWith("/board") || to.path.startsWith("/extras"))
      authRequired = false;
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
export default router